import React, { useEffect, useState } from "react";
import { useLocalization } from "../../hooks/useLocalization";
import useAuth from "../../hooks/useAuth";
import { useDispatch } from "react-redux";

import { formatDateFriendly, getTimeZone } from "../../utils";
import { GetMemberAiMessages } from "../../services/Messages/MessagesService";
import { Loading } from "../../components";
import { useNavigate } from "react-router-dom";
import { url } from "../../routes/utility";
import { setUnreadCount } from "../../store/MessageSlice";

const Messages = () => {
  const { member } = useAuth();
  const strings = useLocalization();
  const navigate = useNavigate();
  const timeZone = getTimeZone();
  const dispatch = useDispatch();

  const [memberAiMessages, setMemberAiMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatDate = (date) => {
    const formattedDate = formatDateFriendly(date, timeZone, {
      today: strings.date.today,
      yesterday: strings.date.yesterday,
      daysAgo: strings.date.days_ago,
    });

    return formattedDate.displayDate;
  };

  useEffect(() => {
    if (member) {
      setLoading(true);
      GetMemberAiMessages(member?.MemberId)
        .then((result) => {
          if (result.status === 200) {
            const messages = JSON.parse(result.content);
            setMemberAiMessages(messages);

            // Okunmamış mesaj sayısını hesapla
            const unreadCount = messages.filter((msg) => !msg.isRead).length;
            dispatch(setUnreadCount(unreadCount));
          }
        })
        .catch()
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <div className="main-content px-4 py-5">
      {loading && (
        <div className="mx-auto">
          <Loading />
        </div>
      )}
      {!loading && (
        <>
          {memberAiMessages.length > 0 ? (
            <div className="space-y-4">
              {memberAiMessages.map((message) => (
                <div
                  key={message.memberAiMessageId}
                  className={`cursor-pointer transform transition-all duration-300 hover:scale-[1.01] flex items-center p-4 rounded-lg border ${!message.isRead
                    ? "border-r-4 border-r-primary bg-primary/10"
                    : "border-box"}hover:shadow-md relative`}
                  onClick={() =>
                    navigate(
                      url("messages.detail", {
                        memberAiMessageId: message.memberAiMessageId,
                      }),
                      {
                        state: {
                          isRead: message.isRead,
                        },
                      }
                    )
                  }
                >
                  <div
                    className={`flex-shrink-0 w-12 h-12 rounded-full flex items-center justify-center ${message.isRead
                      ? "bg-onboarding-border"
                      : "bg-primary"
                      } text-white font-bold text-lg`}
                  >
                    {message.content.charAt(0)}
                  </div>

                  <div className="ml-4 flex-grow">
                    <div className="flex justify-between items-center mb-1">
                      <h3 className="font-bold text-gray-900">
                        {strings.messages?.your_mentor}
                      </h3>
                      <span
                        className={`text-sm mr-2 ${!message.isRead
                          ? "text-primary font-bold"
                          : "text-base-text"
                          }`}
                      >
                        {formatDate(message.createDate)}
                      </span>
                    </div>
                    <p
                      className={`text-sm line-clamp-2 ${!message.isRead
                        ? "font-semibold text-gray-900"
                        : "text-base-text"
                        }`}
                    >
                      {message.content}
                    </p>
                  </div>

                  {!message.isRead && (
                    <div className="w-2 h-2 bg-primary rounded-full absolute top-2 right-2"></div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className=" w-full py-4 min-w-full text-center">
              <p className="font-bold text-[18px] mb-2">{strings.messages.no_messages}</p>
              <p>{strings.messages.no_messages_description}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Messages;
