import sign from "jwt-encode";

export const decodeToken = token => {
    //return jwtDecode(token);
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const encodeToken = (data) => {
    const token = sign(data, process.env.REACT_APP_JWT_SECURITYKEY);
    return token;
}

export const isJWT = (token) => {
    try {
        const tokenParts = token.split('.');
        return tokenParts.length === 3;
    } catch (error) {
        return false;
    }
}

export const parseMobileKey = (key) => {

    const decodedToken = atob(key);

    const parsedToken = new URLSearchParams(decodedToken);

    const memberId = parsedToken.get("MemberId");
    const status = parsedToken.get("Status");
    const path = parsedToken.get("Path");
    const parameter = parsedToken.get("Parameter");
    const categoryId = parsedToken.get("CategoryId");
    const day = parsedToken.get("Day");
    const minute = parsedToken.get("Minute");
    const month = parsedToken.get("Month");
    const memberSlogan = parsedToken.get("MemberSlogan");

    return {
        MemberId: memberId,
        Status: status,
        Path: path,
        Parameter: parameter,
        CategoryId: categoryId,
        Day: day,
        Minute: minute,
        Month: month,
        MemberSlogan: memberSlogan
    };
}

export const parseMobileKeyToJson = (key) => {
    const decodedToken = atob(key);
    const uriDecodedToken = decodeURIComponent(decodedToken);
    return JSON.parse(uriDecodedToken);
}

export const parseMarketRouteKey = (key) => {

    const decodedToken = atob(key);

    const parsedToken = new URLSearchParams(decodedToken);

    const categoryId = parsedToken.get("CategoryId");
    const day = parsedToken.get("Day");
    const minute = parsedToken.get("Minute");
    const month = parsedToken.get("Month");
    const memberSlogan = parsedToken.get("MemberSlogan");

    return {
        CategoryId: categoryId,
        Day: day,
        Minute: minute,
        Month: month,
        MemberSlogan: memberSlogan
    };
}

export const parseSecurePaymentKeyToJson = (key) => {

    const binaryString = atob(key);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    const decodedToken = new TextDecoder('utf-8').decode(bytes);    

    const parsedToken = new URLSearchParams(decodedToken);
    const authCode = parsedToken.get("AuthCode");
    const response = parsedToken.get("Response");
    const errorMessage = parsedToken.get("ErrMsg");

    return {
        AuthCode: authCode,
        Response: response,
        ErrorMessage: errorMessage
    };
}

export const encodeToBase64AndUri = (data) => {
    const jsonString = JSON.stringify(data);
    const encodedString = btoa(encodeURIComponent(jsonString));

    return encodedString;
}

export const decodeToBase64AndUri = (data) => {
    const decodedString = decodeURIComponent(atob(data));
    return JSON.parse(decodedString);
}
