import {useLocalization} from "../../hooks/useLocalization";
import React, {useEffect, useRef, useState} from "react";
import {GetDetailedUseCases} from "../../services/AIUseCase/AIUseCaseService";
import {useSelector} from "react-redux";
import {Brands, ContentTypes} from "../../components/Constants";
import {AIButton, AIChat} from "./components";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {ModalOverlay, ModalTwoButtons} from "../../components";
import {closeModal, openModal} from "../../utils";
import useAuth from "../../hooks/useAuth";
import {url} from "../../routes/utility";
import { CheckTermAttendedStatus } from "../../services/SpeakingLesson/SpeakingLessonService";

const AIPractice = () => {

    const strings = useLocalization();

    const { member } = useAuth();
    const { useCaseId, aiUseCaseType, deeplink } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const language = useSelector(state => state.localizationStore.language);
    const selectedTermId = useSelector(state => state.termStore.termId);

    const audioPlayerRef = useRef();
    const modalOverlayRef = useRef();
    const errorModalRef = useRef();

    const [practices, setPractices] = useState([]);
    const [selectedPractice, setSelectedPractice] = useState({});
    const [isTermAttended, setIsTermAttended] = useState(false);

    const getPractices = async () => {

        const result = await GetDetailedUseCases();

        if (result.statusCode === 200) {
            let resultContent = JSON.parse(result.message);
            setPractices(practices => resultContent);
        }
    }

    const checkTermAttendedStatus = async () => {

        const result = await CheckTermAttendedStatus(selectedTermId);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setIsTermAttended(resultContent);
        }
    }

    const getButtonVisibilityByPaidStatus = (selectedApp, memberStatus) => {
        let status = false;

        const memberTypes = Object.freeze(
            {
                "paidMembers": [2, 3],
                "nonPaidMembers" : [1, 2, 3]
            }
        )

        if(selectedApp) {
            if(selectedApp.paidMember) {
                status = memberTypes.paidMembers.includes(+memberStatus);
            }
            if(selectedApp.nonPaidMember) {
                status = memberTypes.nonPaidMembers.includes(+memberStatus);
            }
            if(selectedApp.demoMember && member?.IsActiveDemoStatus === "True") {
                status = true;
            }
            if(isTermAttended) {
                status = true;
            }
        }

        return status;
    }

    // Path'ten gelen bir useCaseId var ise direkt ilgili ai başlatılıyor.
    useEffect(() => {
        if(practices.length > 0 && useCaseId) {
            let tempSelectedPractice = practices.find(ai => ai.id === +useCaseId);
            setSelectedPractice(tempSelectedPractice)
        }
    }, [practices]);

    useEffect(() => {
        selectedTermId && checkTermAttendedStatus();
    }, [selectedTermId]);

    useEffect(() => {
        if(location.pathname.includes("/chatbothome") && (aiUseCaseType || deeplink))
           navigate(url('speakinglesson.ai'))

        getPractices();
    }, []);

    return (
        <>
            <div className="main-content">
                <div className="left-page">
                    <div className="onboarding-outer-div">
                        {Object.keys(selectedPractice).length === 0 && (
                            practices.length > 0 && practices.map((practice, index) => {
                                    //Web Path ve uygulama id'sine göre butonların görünmesini kontrol ediyoruz.
                                    let selectedApp
                                        = practice.aiUseCaseApplications
                                            .find(p => p.applicationId === Brands.KO
                                                && (p.webPath === location?.pathname || ("/mobile/chatbothome" === location?.pathname && p.webPath !== "-")))
                                                || false;
                                    let buttonTitle = practice?.aiUseCaseTranslations?.find(language => language.languageName === "Turkish");
                                    let visibilityStatus = getButtonVisibilityByPaidStatus(selectedApp, member.Status);

                                    return (
                                        <AIButton
                                            key={index}
                                            title={(buttonTitle && language === "tr") ? buttonTitle.title : practice.title}
                                            icon={practice.aiUseCaseContents.length > 0 && practice.aiUseCaseContents.find(content => content.contentTypeId === ContentTypes.Image).content}
                                            action={() => {
                                                if(visibilityStatus) {
                                                    setSelectedPractice(selectedPractice => practice);
                                                    audioPlayerRef.current.play();
                                                } else {
                                                    openModal(modalOverlayRef, errorModalRef);
                                                }
                                            }}
                                            isVisible={selectedApp}
                                        />
                                    )
                                }
                            )
                        )}
                    </div>

                    <AIChat
                        selectedPractice={selectedPractice}
                        setSelectedPractice={setSelectedPractice}
                        ref={audioPlayerRef}
                    />
                    <div className="left-margin-bottom"></div>
                </div>
            </div>

            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={errorModalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.support.main_page.not_student_content}
                buttonText1={strings.support.main_page.not_student_buy_button}
                buttonClick1={() => {
                    navigate(url("market.packagecatalog"));
                }}
                buttonText2={strings.support.main_page.not_student_cancel_button}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, errorModalRef);
                }}
            />
        </>
    )
}

export default AIPractice;