import { fetchApi } from '../index';
import { Brands } from "../../components/Constants";

export const CreateAIUseCaseSession = async (memberId, aiUseCaseId, bookUnitId = 0) => {
    const url = '/ai/ai_history/ai_use_case_session/create/';

    const payload = {
        payload: {
            memberId: memberId,
            aiUseCaseId: aiUseCaseId,
            applicationId: Brands.KO,
            bookUnitId: bookUnitId
        }
    };

    return await fetchApi(url, payload);
}

export const CreateAIUseCaseSessionLog = async (model) => {
    const url = '/ai/ai_history/ai_use_case_session_log/create/';

    const payload = {
        payload: {
            ...model
        }
    };

    return await fetchApi(url, payload);
}

export const GetAIUseCaseSessionLogsByBookUnitId = async (model) => {
    const url = '/_services/ai_prime_history/v1/ai_use_case_session/list_by_book_unit_id/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetAIUseCaseSessionLogsBySessionId = async (aiUseCaseSessionId) => {
    const url = '/_services/ai_prime_history/v1/ai_use_case_session_log/list/';

    const payload = {
        aiUseCaseSessionId: aiUseCaseSessionId
    };

    return await fetchApi(url, payload);
}

export const GetSessionAndLessonCorrections = async (model) => {
    const url = '/_services/ai_prime_history/v1/ai_use_case_session_correction/get_session_and_lesson_corrections/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const CreateSessionCorrections = async (model) => {
    const url = '/_services/ai_prime_history/v1/ai_use_case_session_correction/create_session_corrections/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const RevertSessionLogs = async (model) => {
    const url = '/_services/ai_prime_history/v1/ai_use_case_session_log/revert_session_logs/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}