import classNames from 'classnames';
import Lottie from 'lottie-react';
import React, { useEffect, useRef, useState } from 'react';
import ReactIntlTelInput from 'react-intl-tel-input-v2';
import { BottomSheet } from 'react-spring-bottom-sheet';
import '../../../../node_modules/intl-tel-input/build/css/intlTelInput.css';
import checkmark from "../../../assets/animation/checkmark.json";
import error from "../../../assets/animation/error.json";
import { Dropdown, ModalContent, ModalOverlay } from '../../../components';
import useAuth from '../../../hooks/useAuth';
import { useLocalization } from '../../../hooks/useLocalization';
import useWindowSize from '../../../hooks/useWindowSize';
import { CorporateCustomerSubmit } from '../../../services/CorporateCustomer/CorporateCustomerService';
import { closeModal, openModal } from '../../../utils';
import { allowLatinCharacters, allowPasteLatinCharacters, validateEmail } from '../../../utils/Helpers';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../routes/utility';
import useAnalytics from '../../../hooks/useAnalytics';
import { EventLogs } from '../../../components/Constants';

const CorporateForm = () => {

  const strings = useLocalization();

  const { member } = useAuth();
  const navigate = useNavigate();
  const analytics = useAnalytics();

  const windowSize = useWindowSize();
  const isMobile = windowSize?.width < 640;

  const modalOverlayRef = useRef();
  const modalRef = useRef();
  const fullnameErrorRef = useRef();
  const emailErrorRef = useRef();
  const phoneErrorRef = useRef();
  const companyNameErrorRef = useRef();
  const employeeCountErrorRef = useRef();

  const employeeCountOptions = [
    { id: 1, name: "1-50" },
    { id: 2, name: "51-250" },
    { id: 3, name: "250+" }
  ];

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    companyName: ''
  });
  const [selectedEmployeeCount, setSelectedEmployeeCount] = useState(employeeCountOptions[0]);
  const [phoneNumberValue, setPhoneNumberValue] = useState({ iso2: 'tr', dialCode: '90', phone: '' });
  const [phoneNumber, setPhoneNumber] = useState('+');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [resultContent, setResultContent] = useState({
    success: false,
    message: ""
  });

  const inputProps = { 
    placeholder: phoneNumberValue.iso2 === 'tr' ? '5XX XXX XX XX' : 'Enter your phone number'
  };
  const intlTelOpts = { preferredCountries: ['tr', 'az', 'de'] };

  const validatePhoneNumber = (phone) => {
    return phone.length > 10;
  };

  const clearError = (ref) => {
    if (ref.current) {
      ref.current.classList.add('hidden');
    }
  };

  const showError = (isValid, ref) => {
    if (!isValid && ref.current) {
      ref.current.classList.remove('hidden');
    }
  };

  const phoneNumberHandle = val => {
    if (val.phone.startsWith('0')) {
      val.phone = val.phone.slice(1);
    }
    if (val.dialCode !== phoneNumberValue.dialCode) {
      setPhoneNumberValue({ iso2: val.iso2, dialCode: val.dialCode, phone: '' });
    } else {
      setPhoneNumberValue(val);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    switch(name) {
      case 'fullName':
        clearError(fullnameErrorRef);
        break;
      case 'email':
        clearError(emailErrorRef);
        break;
      case 'companyName':
        clearError(companyNameErrorRef);
        break;
      default:
        break;
    }
  };

  const resetStates = () => {
    setShowResultModal(false);
    resultContent.success && navigate(url("dashboard"));
    !isMobile && closeModal(modalOverlayRef, modalRef);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    clearError(fullnameErrorRef);
    clearError(emailErrorRef);
    clearError(phoneErrorRef);
    clearError(companyNameErrorRef);
    clearError(employeeCountErrorRef);

    const isValidName = formData.fullName.length >= 2;
    const isValidEmail = validateEmail(formData.email);
    const isValidPhone = validatePhoneNumber(phoneNumber);
    const isValidCompany = formData.companyName.length >= 2;

    if (isValidName && isValidEmail && isValidPhone && isValidCompany) {
      analytics.useAnalytics(EventLogs.CORPORATE_CUSTOMER_DETAILS_SUBMIT);
      setButtonLoading(true);

      const submissionData = {
        memberId: member?.MemberId,
        name: formData.fullName,
        corporateEmail: formData.email,
        phoneNumber: phoneNumber,
        companyName: formData.companyName,
        numberOfEmployees: selectedEmployeeCount.name
      };

      CorporateCustomerSubmit(submissionData)
        .then(result => {
          if(result.status === 200) {
            setResultContent({
              success: true,
              message: strings.corporate_customer.form.success_message
            });
          } else {
            setResultContent({
              success: false,
              message: strings.general_information_messages.an_error_occured
            });
          }
          setShowResultModal(true);
          if(!isMobile) openModal(modalOverlayRef, modalRef);
        })
        .finally(() => {
          setButtonLoading(false);
        });
    } else {
      showError(isValidName, fullnameErrorRef);
      showError(isValidEmail, emailErrorRef);
      showError(isValidPhone, phoneErrorRef);
      showError(isValidCompany, companyNameErrorRef);
    }
  };

  useEffect(() => {
    let phoneNum = '+' + phoneNumberValue.dialCode + phoneNumberValue.phone;
    setPhoneNumber(phoneNum);
  }, [phoneNumberValue]);

  useEffect(() => {
    if(isMobile) {
      const rootElement = document.getElementById('root');
      if (showResultModal) {
        rootElement.setAttribute('inert', '');
        rootElement.removeAttribute('aria-hidden');
      } else {
        rootElement.removeAttribute('inert');
      }

      return () => {
        rootElement.removeAttribute('inert');
      };
    }   
  }, [showResultModal, isMobile]);

  const ResultContent = () => (
    <div className="p-4">
      <div className="flex flex-col items-center gap-4">
        <Lottie 
          animationData={resultContent.success ? checkmark : error}
          loop={false}
          autoplay={true}
          style={{ height: 140, width: 140 }}
        />
        <h2 className="text-center mb-4 whitespace-pre-line">
          {resultContent.message}
        </h2>

        <button
          type="button"       
          className="button primary-button w-full max-w-[330px]"
          onClick={resetStates}
        >
          {strings.modal.okey}
        </button>
      </div>
    </div>
  );

  return (
    <>
      <section className="mt-5 gray-section">
        <h2 className="text-[20px] font-bold text-center">
          {strings.corporate_customer.form.title}
        </h2>
        <p className="text-[18px] text-center mt-2">
          {strings.corporate_customer.form.description}
        </p>
        
        <form className="btn-outer-div mt-5" onSubmit={handleSubmit}>
          <div className="mb-[6px]">
            <label className="block text-start" htmlFor="fullName">
              {strings.auth.form.your_name}
            </label>
            <input
              id="fullName"
              name="fullName"
              type="text"
              value={formData.fullName}
              onChange={handleInputChange}
              className="keypress input-area w-full block"
              placeholder={strings.auth.form.create_your_name}
              onPaste={allowPasteLatinCharacters}
              onKeyDown={allowLatinCharacters}
            />
          </div>
          <p ref={fullnameErrorRef} className="text-[11px] text-red text-start ml-2 hidden font-bold">
            {strings.corporate_customer.form.full_name_error}
          </p>

          <div className="mb-[6px]">
            <label className="block text-start" htmlFor="email">
              {strings.corporate_customer.form.email}
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              className="keypress input-area w-full block"
              placeholder="ornek@sirket.com"
            />
          </div>
          <p ref={emailErrorRef} className="text-[11px] text-red text-start ml-2 hidden font-bold">
            {strings.corporate_customer.form.email_error}
          </p>

          <div className="mb-[6px]">
            <label className="block text-start" htmlFor="phone">
              {strings.auth.form.phone_number}
            </label>
            <ReactIntlTelInput
              className="keypress input-area w-full bg-white"
              inputProps={inputProps}
              intlTelOpts={intlTelOpts}
              value={phoneNumberValue}
              onChange={phoneNumberHandle}
            />
          </div>
          <p ref={phoneErrorRef} className="text-[11px] text-red text-start ml-2 hidden font-bold">
            {strings.corporate_customer.form.phone_number_error}
          </p>

          <div className="mb-[6px]">
            <label className="block text-start" htmlFor="companyName">
              {strings.corporate_customer.form.corporate_name}
            </label>
            <input
              id="companyName"
              name="companyName"
              type="text"
              value={formData.companyName}
              onChange={handleInputChange}
              className="keypress input-area w-full block"
              placeholder={strings.corporate_customer.form.corporate_name_placeholder}
            />
          </div>
          <p ref={companyNameErrorRef} className="text-[11px] text-red text-start ml-2 hidden font-bold">
            {strings.corporate_customer.form.corporate_name_error}
          </p>

          <div className="mb-[6px]">
            <label className="block text-start mb-1" htmlFor="employeeCount">
              {strings.corporate_customer.form.number_of_employees}
            </label>
            <Dropdown
              selected={selectedEmployeeCount}
              setSelected={setSelectedEmployeeCount}
              data={employeeCountOptions}
              classnames="w-full"
            />
          </div>
          <p ref={employeeCountErrorRef} className="text-[11px] text-red text-start ml-2 hidden font-bold">
            {strings.corporate_customer.form.number_of_employees_error}
          </p>

          <button
            type="submit"
            className={classNames("button primary-button max-w-[330px] mx-auto w-full mt-4 mb-3", {
              "opacity-70 pointer-events-none": buttonLoading
            })}
            disabled={buttonLoading}
          >
            {buttonLoading 
              ? strings.general_information_messages.processing 
              : strings.corporate_customer.form.submit
            }
          </button>
        </form>
      </section>

      <ModalOverlay ref={modalOverlayRef} />
      {isMobile ? (
        <BottomSheet
          open={showResultModal}
          onDismiss={resetStates}
          className="mobile-view"
          snapPoints={({ maxHeight }) => [Math.min(425, maxHeight * 0.7)]}
          blocking={true}
          defaultSnap={({ maxHeight }) => Math.min(425, maxHeight * 0.7)}
        >
          <ResultContent />
        </BottomSheet>
      ) : (
        <ModalContent
          ref={modalRef}
          show={showResultModal}
          overlayRef={modalOverlayRef}
          showCloseButton={true}
          closeAction={resetStates}
          className="sm:w-[500px] sm:h-auto"
          aria-modal="true"
        >
          <ResultContent />
        </ModalContent>
      )}
    </>
  );
};

export default CorporateForm; 