import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { staticData } from '../../../assets/data/staticData';
import { Ana_Sayfa_Ko_Mobile } from '../../../assets/img';
import { Target } from '../../../assets/svg';
import { Button, Loading, ModalOverlay, ModalYoutube } from '../../../components';
import { EventLogs } from '../../../components/Constants';
import useAnalytics from '../../../hooks/useAnalytics';
import useAuth from '../../../hooks/useAuth';
import useDemoAvailability from "../../../hooks/useDemoAvailability";
import { useLocalization } from '../../../hooks/useLocalization';
import { url } from '../../../routes/utility';
import { GetDashboardLessonData } from '../../../services/Dashboard/DashboardService';
import { FirebaseContext } from '../../../store/FirebaseProvider';
import {
    addTimeZone,
    formatNoneZoreDateToZeroDateForDot,
    getTimeDiff,
    getTimeZone,
    openModal
} from '../../../utils';
import DashboardOnboardingExercise from "./DashboardOnboardingExercise";
import DemoProgress from './DemoProgress';
import StudentComment from './StudentComment';

const CandidateAndInactiveStudentCommon = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();
    const { member } = useAuth();
    const memberId = member?.MemberId;
    const demoAvailability = useDemoAvailability(memberId);

    //exerciseType = 0 ise Günün Çalışması görünecek
    //exerciseType = 1 ise Videolarla İngilizce Öğrenmenin Keyfine Var! görünecek
    const exerciseType = useContext(FirebaseContext).exerciseType;

    const language = useSelector(state => state.localizationStore.language);
    const timeZone = getTimeZone(member.Timezone);

    const terms = member.Terms && JSON.parse(member.Terms);
    const selectedTermId = useSelector(state => state.termStore.termId);
    const selectedTerm = terms && terms.find(term => term.TermId === selectedTermId) || false;

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const videoCount = staticData.studentComments.length;

    const [lessonInfo, setLessonInfo] = useState({ "text": "", "teacherName": "", "teacherImg": "" });
    const [videoUrl, setVideourl] = useState('');
    const [lessonData, setLessonData] = useState(false);
    const [loading, setLoading] = useState(false);
    
    //from child dropdown
    const checkDotForDate = dt => {
        return dt.includes(".");
    }

    const createDateFromDotDate = dt => {
        let dateArr = dt.split(".");
        let date = dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
        return date;
    }

    const createDateFromDotDateToDashDateWithTime = (date, lessonTime) => {
        let [day, month, year] = date.split('.');
        let [hour, minute] = lessonTime.split(':');

        // Eğer "day" değeri 10'dan küçükse başına sıfır ekleniyor
        // Eğer "day" değeri 10'dan küçükse ve tek haneli olarak geliyorsa başına sıfır ekleniyor
        if (parseInt(day) < 10 && day.length === 1) {
            day = `0${day}`;
        }

        // Eğer "month" değeri 10'dan küçükse ve tek haneli olarak geliyorsa başına sıfır ekleniyor
        if (parseInt(month) < 10 && month.length === 1) {
            month = `0${month}`;
        }

        return new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);
    }

    const showDateWithFormat = dt => {
        let dateArr = dt.split(".");
        let date = ("0" + dateArr[0]).slice(-2) + "." + ("0" + dateArr[1]).slice(-2) + "." + dateArr[2];
        return date;
    }

    const getDashboardLessonData = async (termId) => {
        setLoading(true);

        const result = await GetDashboardLessonData(termId)

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setLessonData(resultContent);
        }

        setLoading(false);
    }

    //when termId change get term info
    useEffect(() => {
        let abortController = new AbortController();

        if (selectedTermId) {
            getDashboardLessonData(selectedTermId);
        }

        return () => {
            abortController.abort();
        };
    }, [selectedTermId])

    //update lesson dates
    useEffect(() => {
        //if there is data for lesson
        if (lessonData) {
            //if lesson data has a date
            if (lessonData.lessonDate !== undefined) {

                let lessonDate = formatNoneZoreDateToZeroDateForDot(lessonData.lessonDate);
                if (checkDotForDate(lessonData.lessonDate)) {
                    lessonDate = createDateFromDotDate(lessonDate);
                }

                //get time difference for next lesson
                const counterLesson = getTimeDiff(new Date(`${lessonDate} ${addTimeZone(lessonData.lessonTime, timeZone)}`), new Date());

                //create an object for lesson info content
                let info = { "text": "", "teacherName": "", "teacherImg": "" };
                let lessonMessage = "";

                if (createDateFromDotDateToDashDateWithTime(lessonData.lessonDate, addTimeZone(lessonData.lessonTime, timeZone)) >= new Date()) {
                    //change values for current language
                    lessonMessage = strings.dashboard.demo_student.lesson_info;
                    lessonMessage = lessonMessage.replace(/#counter#/g, counterLesson);
                    lessonMessage = lessonMessage
                        .replace(/#name#/g, selectedTerm?.StudentName || member.FullName || "")
                        .replace(/#date#/g, showDateWithFormat(lessonData.lessonDate))
                        .replace(/#time#/g, addTimeZone(lessonData.lessonTime, timeZone))
                        .replace(/#day#/g, strings.dashboard.student.day)
                        .replace(/#hour#/g, strings.dashboard.student.hour)
                        .replace(/#minute#/g, strings.dashboard.student.minute);

                } else {
                    lessonMessage = strings.dashboard.student.in_lesson_time_message;
                    lessonMessage = lessonMessage.replace(/#name#/g, member.FullName ?? "");
                }

                //update info object
                info.text = lessonMessage;
                info.teacherName = lessonData.teacherModel?.fullName.replace(" (Sanal Egitmen)", "");
                info.teacherImg = lessonData.teacherModel?.profilePicture;
                //set lesson info for render
                setLessonInfo(info);
            }
        }
    }, [lessonData, language])

    return (
        <>
            {loading && <Loading />}

            {!loading && (demoAvailability || member?.IsActiveDemoStatus === "True") &&
                <DemoProgress 
                    lessonData={lessonData}
                    lessonInfo={lessonInfo}
                    terms={terms}
                />
            }

            {/* Loading anında kayma olduğu için sabit height verildi */}
            {exerciseType === 0 && (
                <div className="h-[374px]">
                    <DashboardOnboardingExercise />
                </div>
            )}

            {exerciseType === 1 && (
                <div className="transparent-section mt-4">
                    <h5 className="text-secondary">{strings.dashboard.video_exercise.title}</h5>
                    <p className="mt-[27px]">{strings.dashboard.video_exercise.content}</p>
                    <div className="btn-outer-div my-5">
                        <Button
                            action={() => {
                                analytics.useAnalytics(EventLogs.START_TO_EXPLORE_WITH_VIDEO_BUTTON_CLICKED)
                                navigate(url("videopractice"));
                            }}
                            classnames="button primary-button"
                            text={strings.dashboard.video_exercise.button_text}
                        />
                    </div>
                </div>
            )}

            <div className="gray-section mt-5">
                <h5 className="text-primary">{strings.mentor.title}</h5>
                <div className='flex flex-col xs:flex-row items-center max-w-xl mx-auto gap-3 sm:gap-8 md:mb-10'>
                    <div>
                        <p className="mt-[27px] xs:text-start">{strings.mentor.dashboard_message_1}</p>
                        <p className="mt-[27px] xs:text-start">{strings.mentor.dashboard_message_2}</p>
                    </div>
                    <img className="w-[80px] xs:w-[100px] h-[80px] xs:h-[100px]" src={Target} alt="" />

                </div>
                <div className="btn-outer-div my-5">
                    <Button action={() => {
                        analytics.useAnalytics(EventLogs.ABOUT_MENTOR_CLICK)
                        navigate(url("mentor"))
                    }}
                        classnames="button secondary-button"
                        text={strings.mentor.about_mentor}
                    />
                </div>
            </div>

            <div className="btn-outer-div my-5">
                <Link 
                    to={url("market.packagecatalog")} 
                    className="button primary-button" 
                    onClick={() => analytics.useAnalytics(EventLogs.REVIEW_PACKAGES_CLICK)}
                >
                    {strings.dashboard.none_user.view_packages}
                </Link>
            </div>

            <div className="bg-box rounded-[10px] px-[10px] py-5 mx-auto text-center w-full">
                <h5 className="text-primary mb-2">{strings.dashboard.none_user.student_comments}</h5>
                <div className={classNames('flex flex-col justify-center mx-auto gap-6 w-full items-center', { 'sm:flex-wrap sm:flex-row': videoCount !== 1 })}>
                    {
                        staticData.studentComments.map((comment, index) => {

                            let imgUrl = "https://img.youtube.com/vi/" + comment.youtubeId + "/0.jpg";

                            return (
                                <StudentComment key={index} imgUrl={imgUrl}
                                    studentName={comment.name}
                                    studentJob={comment.job[language]}
                                    videoClick={() => {
                                        setVideourl("https://www.youtube.com/embed/" + comment.youtubeId);
                                        openModal(modalOverlayRef, modalRef);
                                        analytics.useAnalytics('Testimonial_Video', {
                                            video: comment.name,
                                        });
                                    }}
                                    playVideo={strings.dashboard.none_user.play_video} />
                            )
                        })
                    }

                    {staticData.studentComments.length % 2 !== 0 && (<div className="hidden sm:block sm:w-[48%] max-w-[246px] relative"></div>)}

                </div>
            </div>
            
            <div className="left-margin-bottom"></div>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalYoutube
                ref={modalRef}
                videoUrl={videoUrl}
                overlayRef={modalOverlayRef}
            />

        </>
    )
}

export default CandidateAndInactiveStudentCommon