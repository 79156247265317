import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { url } from '../../../../routes/utility';
import useAnalytics from '../../../../hooks/useAnalytics';
import { useLocalization } from '../../../../hooks/useLocalization'
import { EventLogs } from '../../../../components/Constants';

const Inactive = () => {

    const strings = useLocalization();
    const analytics = useAnalytics();
    const navigate = useNavigate();

    useEffect(() => {
        analytics.useAnalytics('screen_view', { screen_name: 'Gunun_Dersi_Aktif_Ders_Yok' });
        window.customDataLayer.push({ event: 'screen_view', screen_name: 'Gunun_Dersi_Aktif_Ders_Yok' });
    }, []);

    return (
        <>

            <div className="gray-section-bottom">

                <div className="flex flex-col sm:flex-row sm:justify-between text-sm mt-4 mx-auto max-w-lg">
                    <div className="text-center">
                        <p className="mb-5">
                            {strings.speaking_lesson.components.inactive.content_1}
                        </p>
                        <p className="mb-5">
                            {strings.speaking_lesson.components.inactive.content_2}
                        </p>

                        <div className="btn-outer-div my-4">
                            <button
                                className="button primary-button"
                                onClick={() => {
                                    analytics.useAnalytics(EventLogs.REVIEW_PACKAGES_CLICK);
                                    navigate(url('market.packagecatalog'));
                                }}
                            >
                                {strings.teachers.teacher_details.not_student_buy_button}
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Inactive