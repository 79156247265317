import classNames from "classnames";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Keyboard, Recording, SendIcon, StopRecording } from "../../../../assets/svg";
import { Loading, ModalOverlay, ModalTwoButtons } from "../../../../components";
import { ActionTypes, AIKeys, EventLogs, LevelCollection, LevelDefinitionType, MemberStatus } from "../../../../components/Constants";
import useAction from "../../../../hooks/useAction";
import useAiRouting from "../../../../hooks/useAiRouting";
import useAnalytics from "../../../../hooks/useAnalytics";
import useAuth from "../../../../hooks/useAuth";
import { useLocalization } from "../../../../hooks/useLocalization";
import { url } from "../../../../routes/utility";
import { ChatbotWithTTS, Transcribe } from "../../../../services/AIUseCase/AIUseCasePythonService";
import { UseCaseGenericService } from "../../../../services/AIUseCase/AIUseCaseService";
import {
    CreateAIUseCaseSession,
    CreateAIUseCaseSessionLog
} from "../../../../services/AIUseCaseHistory/AIUseCaseHistoryService";
import { AddMemberLevelCollection } from "../../../../services/Auth/AuthService";
import {
    closeModal,
    getBrowserName,
    getDeviceOSName,
    getPermissionTutorialVideoUrl,
    hasNonWhitespaceCharacter,
    openModal
} from "../../../../utils";
import { AILoader } from "../index";

const AIChat = forwardRef((props, ref) => {

    const {
        selectedPractice,
        isFirstMessageBlue = false,
        backButtonAction = () => window.location.href = "/speakinglesson/ai",
        logParameters,
        isEmbed = false
    } = props;

    const strings = useLocalization();

    let correctAndSpeakAI = "Correct and Speak (AI)";
    let interviewProAI = "Interview Pro (AI)";
    let levelTestAI = "Level Test (AI)";
    let grammarExerciseAI = "Learn Grammar Exercise";
    let videoExerciseAI = "Video Exercise AI";
    let examPreparationAI = "Exam Preparation (AI)";

    const systemMemberId = process.env.REACT_APP_SYSTEM_USER_MEMBER_ID;

    const location = useLocation();
    const navigate = useNavigate();
    const language = useSelector(state => state.localizationStore.language);
    const {member} = useAuth();
    const selectedTermId = useSelector(state => state.termStore.termId) || false;
    const analytics = useAnalytics();
    const action = useAction();

    const levelTestUseCaseId = useAiRouting(AIKeys.LEVEL_FINDER);
    const dateFromState = location?.state?.date || null;
    let externalCorrections = location?.state?.corrections || [];

    const browser = getBrowserName();
    const operatingSystem = getDeviceOSName();

    const mediaRecorderRef = useRef(null);
    const chatScreenRef = useRef();
    const soundButtonRef = useRef();
    const modalOverlayRef = useRef();
    const modalRef = useRef();

    //Sayfa yüklenmesi
    const [loading, setLoading] = useState(false);
    //Chatbot'tan gelen cevaplarda yükleniyor componentinin çalışmasını sağlayan state
    const [chatbotLoading, setChatbotLoading] = useState();
    //Kullanıcıdan gelen cevaplarda yükleniyor componentinin çalışmasını sağlayan state
    const [transcribeLoading, setTranscribeLoading] = useState(false);
    const [showMicrophoneButton, setShowMicrophoneButton] = useState(false);
    const [title, setTitle] = useState("");

    //Eğer chat başlatmak için herhangi bir servise gidilmişse o servisten dönmesi muhtemel hata durumunu tutan state.
    const [isGenericServiceError, setIsGenericServiceError] = useState();
    //Eğer chat başlatmak için herhangi bir servise gidilmişse o servisten dönen veriyi tutan state.
    const [responseData, setResponseData] = useState([]);
    //Eğer chat başlatmak için herhangi bir servise gidilmişse ve ilgili serviste bookUnitId var ise, tutulduğu state
    const [bookUnitId, setBookUnitId] = useState(-1);
    //Dış kaynak bir servise gidilirse dönen cevabın kullanıcıya gösterilip/gösterilmemesine karar veren state.
    const [isShowUser, setIsShowUser] = useState(false);
    //Ses kaydının başlama durumunu tutan state.
    const [isRecording, setIsRecording] = useState(false);
    //Tüm mesajların tutulduğu state.
    const [messages, setMessages] = useState([]);
    //Mesajlar array'ine herhangi bir mesaj eklenme durumunu tutan state. (Bu sayede her mesaj eklendiğinde servise gidiyoruz.
    const [sendMessage, setSendMessage] = useState(false);
    //Log için bir session başladığında gelen sessionId'yi tutan state.
    const [useCaseSessionId, setUseCaseSessionId] = useState("");
    //TTS servisten #endOfPrompt# ibaresi gelirse bu state true'ya çekilecek.
    const [endOfPrompt, setEndOfPrompt] = useState(false);
    // "Konuşmaya Başla" logu bir defa atılacağı için state ile durum kontrol ediliyor.
    const [isStartConversationLogSended, setIsStartConversationLogSended] = useState(false);
    //AI ses durumunu belirlemek için kullanılan state
    const [volumeUp, setVolumeUp] = useState(false);
    // AI Mikrofon izni verildi logunun gönderilme durumunu tutan state
    const [isGiveMicrophonePermissionLogSend, setIsGiveMicrophonePermissionLogSend] = useState(false);
    // AI Mikrofon izni reddedildi logunun gönderilme durumunu tutan state
    const [isNotGiveMicrophonePermissionLogSend, setIsNotGiveMicrophonePermissionLogSend] = useState(false);
    // Mikrofon izin durumunu tutan state
    const [microphoneStatus, setMicrophoneStatus] = useState("");
    // Eğer embed true ise ses çaldırma ref'inin tetiklendiğini tutan state
    const [isClickedVolumeUp, setIsClickedVolumeUp] = useState(false);
    //Hangi voice ile başladıysak o voice ile devam etmemiz gerekiyor
    const [ttsVoice, setTtsVoice] = useState(-1);

    /* Speak or Text Mode States */

    //"Konuşmaya Başla" alanının görünüp/görünmemesini sağlayan state.
    const [showMicrophone, setShowMicrophone] = useState(true);
    //"Yazarak Mesaj Gönder" alanının görünüp/görünmemesini sağlayan state.
    const [showTextarea, setShowTextarea] = useState(false);
    //Yazılan textin tutulduğu state.
    const [text, setText] = useState("");
    //Text areanın scrollable olup/olmayacağını kontrol eden state.
    const [textIsScrollable, setTextIsScrollable] = useState(false);

    /* Speak or Text Mode States */

    //Servisten gelen endpoint içerisinde bulunan parametreleri kontrol etmek için kullanılan obje.
    const EndpointParameters = Object.freeze(
        {
            "memberId": 1,
            "termId": 2,
            "date": 3
        }
    )

    //Herhangi bir endpointe gidilmişse o endpointten gelecek hatalar burada tutuluyor.
    const errorLogs = [
        {id: 1001, name: strings.ai_chatbot.errors.correction_not_found_error},
        {id: 1002, name: strings.ai_chatbot.errors.selected_term_not_found_error},
    ]

    //Python api'ye gönderilen rolleri backend'e uygun hale getiren fonksiyon.
    const getRoleIdByRoleName = (roleName) => {
        switch (roleName) {
            case "system":
                return 0;
            case "user":
                return 1;
            case "assistant":
                return 2;
            default:
                return -1;
        }
    }

    //Python API için prompt'u hazırlayan servis.
    const splitPrompt = (prompt) => {
        const systemKeyword = "$system$";
        const userKeyword = "$user$";

        const sections = prompt.split('_$_');
        let systemPrompt = "";
        let userPrompt = "";

        sections.map((section) => {
            const systemIndex = section.indexOf(systemKeyword);
            const userIndex = section.indexOf(userKeyword);

            if (systemIndex !== -1) {
                systemPrompt = section.substring(systemIndex + systemKeyword.length);
            }

            if (userIndex !== -1) {
                userPrompt = section.substring(userIndex + userKeyword.length);
            }
        })

        return {
            systemPrompt: systemPrompt.trim(),
            userPrompt: userPrompt.trim()
        };
    }

    const capitalizeFirstLetter = (str) => {
        return str.length === 0 ? str : str.charAt(0).toUpperCase() + str.slice(1);
    }

    //Chat başlatmak için bir endpoint'e gidilecekse bu fonksiyon kullanılıyor.
    //Body kısmı zorunlu olmadığı için kontrol ediliyor. Body boş olsa bile servise gidiliyor.
    const aiUseCaseGenericService = async (url, body = "") => {
        setLoading(loading => true);
        let result;

        if(externalCorrections.length === 0) {
            if (body.length > 0) {
                let replacedBody =
                    body
                        .replace(EndpointParameters?.memberId, "guidMemberId")
                        .replace(EndpointParameters?.termId, "guidTermId")
                        .replace(EndpointParameters?.date, "selectedDate")
    
                let replacedServiceBody =
                    replacedBody
                        .replace("guidMemberId", `"${member.MemberId}"`)
                        .replace("guidTermId", `"${selectedTermId}"`)
                        .replace("selectedDate", dateFromState ? `"${dateFromState}"` : null);
    
                result = await UseCaseGenericService(url, JSON.parse(replacedServiceBody));

                let parsedResult = JSON.parse(result.message);
                let tmpResult = parsedResult.message;

                tmpResult = tmpResult.map(item => ({
                    ...item,
                    content: capitalizeFirstLetter(item.content)
                }));

                setIsShowUser(parsedResult.isShowUser);
                setResponseData(tmpResult);
                setBookUnitId(JSON.parse(result.message)?.bookUnitId);

            } else {
                result = await UseCaseGenericService(url);
            }
        } else {
            externalCorrections = externalCorrections.map((sentence, index) => ({
                title: "Correction: " + (index + 1),
                content: sentence
            }));

            setIsShowUser(true);
            setResponseData(externalCorrections);
        }

        setLoading(loading => false);
    }

    //Python servise gönderilen ve gelen mesajları "messages" array'ine atmamıza yarayan fonksiyon.
    //Her mesaj geldiğinde backend'e log olarak atılıyor.
    const addMessage = (role, message) => {
        setTimeout(() => {
            createSessionLog(message, getRoleIdByRoleName(role), useCaseSessionId);
        }, 700);
        setMessages(messages => [...messages, {"role": role, content: message}]);
    }

    //TTS servise gitmeden önce promptların ayarlandığı fonksiyon.
    const chatbotWithTTS = async (responseData = []) => {

        let prompt = selectedPractice.aiUseCasePrompt;

        //Chat başlatmak için farklı bir endpoint'e gidildiyse oradan gelen cevap user prompt'ta bulunan,
        // #endpoint# alanına replace ediliyor.
        if (responseData.length > 0) {
            const endpointData = responseData.map((data) => {
                return `${data.title}: ${data.content}`;
            }).join(', ');

            prompt = prompt.replace("#endpoint#", endpointData);
        }

        //System ve User prompt'u alınıyor.
        let {systemPrompt, userPrompt} = splitPrompt(prompt);

        //System prompt'u messages dizisine atılıyor.
        addMessage("system", systemPrompt);

        //User prompt'u messages dizisine atılıyor.
        addMessage("user", userPrompt);

        //Servise gidilmesi için uygun hale geldiğimizi anlamak için sendMessage state'i true'ya çekiliyor.
        setSendMessage(sendMessage => true);
    }

    //Backend'e log atmak için session oluşturan servis.
    const createSession = async (memberId, aiUseCaseId) => {

        const result = await CreateAIUseCaseSession(memberId, aiUseCaseId);

        if(result.statusCode === 200) {
            setUseCaseSessionId(useCaseSessionId => JSON.parse(result.message));
        }
    }

    //Backend'e her mesajı log atan servis.
    const createSessionLog = async (message, roleId, useCaseSessionId) => {

        let model = {
            contentText: message,
            aiUseCaseRoleId: roleId,
            aIUseCaseSessionId: useCaseSessionId
        }

        await CreateAIUseCaseSessionLog(model)
            .then(r => {})
            .catch(r => {})
    }

    //Kullanıcı yazma moduna geçmek istediğinde kullanılan fonksiyon.
    const handleTextareaButton = () => {
        setShowMicrophone(showMicrophone => false);
        setShowTextarea(showTextarea => true);

        if(selectedPractice.title === grammarExerciseAI && !isEmbed)
            analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_GRAMMAR_TEST_WRITE_CLICK, logParameters);

        if(selectedPractice.title === videoExerciseAI && !isEmbed)
            analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_AI_PRACTICE_WRITE_CLICK, logParameters);
    }

    //AI seslendirmesini kontrol eden fonksiyon.
    const handleVolumeControlButton = () => {
        setVolumeUp(volumeUp => !volumeUp);
    }

    //Kullanıcı mikrofon moduna geçmek istediğinde kullanılan fonksiyon.
    const handleMicrophoneButton = () => {
        setShowMicrophone(showMicrophone => true);
        setShowTextarea(showTextarea => false);
    }

    const handleBackButton = () => {
        if (externalCorrections.length > 0) {
            navigate(-1);
            return;
        }

        if(!dateFromState)
            backButtonAction();
        else
            backToDailyLessonByDate();

        if(selectedPractice.title === levelTestAI && !isEmbed)
            analytics.useAnalytics(EventLogs.PLACEMENT_TEST_BACK);
    }

    const backToDailyLessonByDate = () => {
        localStorage.setItem("selectedDate", dateFromState);
        navigate(url("speakinglesson.dailylesson"));
    }

    //Textarea'nın size'ını ayarlayan fonksiyon
    const autoResize = (event) => {
        const textarea = event.target;

        if(textarea.scrollHeight > 100)
            setTextIsScrollable(textIsScrollable => true);
        else
            setTextIsScrollable(textIsScrollable => false);

        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
    };

    //Kullanıcıdan ses kaydı almak için kullanılan fonksiyon.
    const startRecording = async () => {
        let chunks = [];

        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true}) // To get access to the microphone

            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(function (stream) {
                    if(!isGiveMicrophonePermissionLogSend) {
                        !isEmbed && analytics.useAnalytics(EventLogs.AI_MICROPHONE_ALLOWED);
                        setIsGiveMicrophonePermissionLogSend(true);
                    }
                })

            ref.current.pause();
            //mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' }); // Set the mimeType to 'audio/webm'
            mediaRecorderRef.current = new MediaRecorder(stream); // To create a media recorder object

            mediaRecorderRef.current.addEventListener('dataavailable', (event) => {
                chunks.push(event.data); // To store the audio data in chunks
            });

            mediaRecorderRef.current.addEventListener('stop', async () => {
                const blob = new Blob(chunks, {type: 'audio/wav'}); // To create a blob object from the chunks
                const formData = new FormData(); // To create a form data object
                formData.append('audio', blob); // To append the blob object to the form data

                //Ses kaydı alındıktan sonra blob formatından birleştirilip formData olarak transcribe servise gönderiliyor.
                setTranscribeLoading(transcribeLoading => true);
                let userMessage = await Transcribe(formData);

                //Transcribe servisten gelen text kullanıcıya gösteriliyor.
                chatScreenRef.current.innerHTML += ` 
                    <div class="block max-w-max relative p-3 rounded-lg mt-2 message-box-member bg-primary ml-auto text-white">
                       ${userMessage}
                    </div>
                `

                //Tekrardan TTS servise gönderilmesi için messages dizine de Transcribe servisten gelen text atılıyor.
                addMessage("user", userMessage, useCaseSessionId);
                setSendMessage(sendMessage => true);
                setTranscribeLoading(transcribeLoading => false);
                scrollToBottom();

                // Reset the chunks array
                chunks = [];
            });

            mediaRecorderRef.current.start(); // To start recording audio
            setIsRecording(true) // To set the recording state to true


            setTimeout(() => {
                scrollToBottom();
            }, 500);

        } catch (error) {
            console.error('Error accessing microphone:', error); // To log the error message

            navigator.mediaDevices.getUserMedia({ audio: true })
                .then()
                .catch((e) => {
                    if(!isNotGiveMicrophonePermissionLogSend && microphoneStatus !== "denied") {
                        !isEmbed && analytics.useAnalytics(EventLogs.AI_MICROPHONE_NOT_ALLOWED);
                        setIsNotGiveMicrophonePermissionLogSend(true);
                    } else {
                        openModal(modalOverlayRef, modalRef);
                    }
                })
        }
    }

    const startText = (text) => {
        setTranscribeLoading(transcribeLoading => true);

        //Transcribe servisten gelen text kullanıcıya gösteriliyor.
        chatScreenRef.current.innerHTML += ` 
            <div class="block max-w-max relative p-3 rounded-lg mt-2 message-box-member bg-primary ml-auto text-white">
               ${text.replace(/\n/g, '<br>')}
            </div>
        `

        addMessage("user", text, useCaseSessionId);
        setSendMessage(sendMessage => true);
        ref.current.pause();
        setText("");
        setTranscribeLoading(transcribeLoading => false);

        scrollToBottom();
    }

    const stopRecording = () => {
        if (isRecording) { // If recording is in progress
            mediaRecorderRef.current.stop(); // To stop recording audio
            setIsRecording(false); // To set the recording state to false
        }
    }

    // Scroll'u en alta kaydırma işlemi
    const scrollToBottom = () => {
        if (chatScreenRef.current.clientHeight > 250) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }

    //Chat başlatmak için farklı bir servise gidildiyse o servisteki hata durumunu kontrol eden fonksiyon
    const isResponseError = (responseData) => {
        if(responseData.length > 0){
            let isHaveError = responseData.find(err => err.title === 'Error');

            return !!(isHaveError && Object.keys(isHaveError).length > 0);
        }
    }

    useEffect(() => {
        const checkMicrophoneAllowed = async () => {
            let microphonePermissionStatus = navigator.permissions.query({ name: 'microphone' })
                .then(permissionStatus => {
                    return permissionStatus.state
                })
            return await microphonePermissionStatus
        }

        checkMicrophoneAllowed().then(status => {
            if(status === "granted")
                setIsGiveMicrophonePermissionLogSend(isHaveMicrophonePermission => true);
            else
                setMicrophoneStatus(status);
        })
    }, []);

    useEffect(() => {
        //System ve User prompt ayarlandıktan sonra sendMessage state'i dinlenerek servise gidiliyor.
        const sendToEndpoint = async () => {
            /*
                Seçili pratiğe göre "Mesaj Gönderme" işlemi için log atılıyor.
                messages dizisinde length kontrolü yapılarak chat'e prompt gönderirken log gönderilmesi engellendi.
            */
            if(selectedPractice.title === correctAndSpeakAI && messages.length > 2 && !isEmbed)
                analytics.useAnalytics(EventLogs.CORRECT_AND_SPEAK_MESSAGE_SENDED);

            if(selectedPractice.title === interviewProAI && messages.length > 2 && !isEmbed)
                analytics.useAnalytics(EventLogs.INTERVIEW_PRO_MESSAGE_SENDED);

            if(selectedPractice.title === levelTestAI && messages.length > 2 && !isEmbed)
                analytics.useAnalytics(EventLogs.PLACEMENT_TEST_MESSAGE_SEND);

            if(selectedPractice.title === grammarExerciseAI && messages.length > 2 && !isEmbed)
                analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_GRAMMAR_TEST_MESSAGE_SEND, logParameters);

            if(selectedPractice.title === videoExerciseAI && messages.length > 2 && !isEmbed)
                analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_AI_PRACTICE_MESSAGE_SEND, logParameters);

            if(selectedPractice.title === examPreparationAI && messages.length > 2 && !isEmbed)
                analytics.useAnalytics(EventLogs.PREPARATION_EXAMS_MESSAGE_SENDED);

            setChatbotLoading(true);
            const result = await ChatbotWithTTS(JSON.stringify(messages), ttsVoice);
            if (result.statusCode === 200) {

                if (result.message.voice)
                    setTtsVoice(parseInt(result.message.voice));

                let replacedResponse = result.message.content.replace("#endofprompt#", "");
                result.message.content.includes("#endofprompt#") && setEndOfPrompt(showMicrophoneButton => true);

                if(result.message.content.includes("#endofprompt#") && selectedPractice.title === levelTestAI && !isEmbed) {
                    const levelArray = Object.entries(LevelCollection).map(([key, value]) => ({
                        id: value,
                        name: key.toLowerCase()
                    }));

                    for (const level of levelArray) {
                        if (result.message.content.toLowerCase().includes(level.name)) {
                            AddMemberLevelCollection(member?.MemberId, LevelDefinitionType.AI, level.id)
                                .then()
                                .catch()

                            //LOGGED
                            analytics.useAnalytics(EventLogs.AI_LEVEL_COMPLETED);
                            break;
                        }
                    }
                }

                /*
                    Seçili pratiğe göre "Mesaj Alınma" işlemi için log atılıyor.
                */
                if(selectedPractice.title === correctAndSpeakAI && !isEmbed)
                    analytics.useAnalytics(EventLogs.CORRECT_AND_SPEAK_MESSAGE_RECEIVED);

                if(selectedPractice.title === interviewProAI && !isEmbed)
                    analytics.useAnalytics(EventLogs.INTERVIEW_PRO_MESSAGE_RECEIVED);

                if(selectedPractice.title === levelTestAI && !isEmbed)
                    analytics.useAnalytics(EventLogs.PLACEMENT_TEST_MESSAGE_RECEIVED);

                if(selectedPractice.title === grammarExerciseAI && !isEmbed)
                    analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_GRAMMAR_TEST_MESSAGE_RECEIVED, logParameters);

                if(selectedPractice.title === videoExerciseAI && !isEmbed)
                    analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_AI_PRACTICE_MESSAGE_RECEIVED, logParameters);

                if(selectedPractice.title === examPreparationAI && !isEmbed)
                    analytics.useAnalytics(EventLogs.PREPARATION_EXAMS_RECEIVED);

                //Servisten gelen asistan cevabı messages dizisine atılıyor.
                addMessage("assistant", replacedResponse);

                if(chatScreenRef?.current) {
                    //Asistan cevabı kullanıcıya gösteriliyor.
                    chatScreenRef.current.innerHTML += `
                        <div class="block max-w-max relative p-3 rounded-lg mt-2 
                            ${(messages.length === 2 && isFirstMessageBlue)
                                    ? 'message-box-endpoint bg-blue-100'
                                    : 'message-box-assistant bg-box'
                                }"
                        >
                            ${(isFirstMessageBlue && messages.length === 2)
                                ? `<p class="whitespace-pre-line">${replacedResponse.replace(/#bold#(.*?)#bold#/g, "<b>$1</b>")}</p>`
                                : `<p class="whitespace-pre-line">${replacedResponse}</p>`
                            }
                        </div>
                    `;
                }

                if (!showTextarea) {
                    //Servisten gelen ses kaydı çaldırılıyor.
                    soundButtonRef.current.addEventListener('click', function () {
                        ref.current.src = result.message.audio;
                        ref.current.play();
                    });

                    setTimeout(function () {
                        soundButtonRef.current.click();
                    }, 500);
                }

                setChatbotLoading(chatbotLoading => false);
                setSendMessage(sendMessage => false);
                scrollToBottom();
            }
        }

        sendMessage && sendToEndpoint();
    }, [messages])

    /*
        Tüm useEffect kancalarında önce selectedPractice props'unun doluluğunu kontrol ediyoruz. Bunun nedeni;
        seçili bir aiPractice olmadan işlem yapmamaktır.
    */
    useEffect(() => {
        if (Object.keys(selectedPractice).length > 0) {
            //Seçili AI Pratiği için requestBody varsa servise request body'de gönderiyoruz.
            if (selectedPractice?.aiUseCaseEndpointRequestBody.length !== 0) {
                aiUseCaseGenericService(selectedPractice.aiUseCaseEndpointUrl, selectedPractice.aiUseCaseEndpointRequestBody)
            } else if (selectedPractice?.aiUseCaseEndpointUrl.length !== 0) {
                aiUseCaseGenericService(selectedPractice.aiUseCaseEndpointUrl)
            }
        }
    }, [selectedPractice]);

    //Örnek Pratik --> Mülakat
    useEffect(() => {
        if (Object.keys(selectedPractice).length > 0) {
            //Seçili AI Pratiği için herhangi bir endpoint yoksa sadece session durumunu kontrol edip TTS servise gidiyoruz.
            if(selectedPractice.aiUseCaseEndpointUrl.length === 0 && useCaseSessionId.length > 0) {
                chatbotWithTTS();
            }
        }
    }, [selectedPractice, useCaseSessionId]);

    //Örnek Pratik --> Correction
    useEffect(() => {
        //Seçili AI Pratiği için herhangi bir endpoint gerekiyorsa endpointten gelen cevabın tutulduğu dizi,
        //Session durumu,
        //Gereken endpointin hata durumu kontrol ediliyor ve TTS servise gidiliyor.
        if(Object.keys(selectedPractice).length > 0 && useCaseSessionId.length > 0 && responseData.length > 0 && isGenericServiceError === false) {
            chatbotWithTTS(responseData);
        }
    }, [selectedPractice, useCaseSessionId, responseData, isGenericServiceError]);

    //Eğer bir servise gidilmişse o servisten herhangi bir hata mesajı dönmüş mü ona bakılıyor.
    useEffect(() => {
        if(Object.keys(selectedPractice).length > 0) {
            setIsGenericServiceError(isResponseError(responseData));
        }
    }, [responseData, selectedPractice]);

    //Session oluşturmak için hata kontrolü yapılıyor. Bir endpoint gerekmiyorsa hata durumuna bakılmadan session oluşturuluyor.
    useEffect(() => {
        if(Object.keys(selectedPractice).length > 0) {
            const memberId = isEmbed ? systemMemberId : (member?.MemberId ?? systemMemberId);

            if(isGenericServiceError === false) {
                createSession(memberId, selectedPractice.id);
            } else if (selectedPractice?.aiUseCaseEndpointUrl.length === 0) {
                createSession(memberId, selectedPractice.id);
            }
        }
    }, [isGenericServiceError, selectedPractice]);

    //Mikrofon butonunun görünme durumu kontrol ediliyor
    useEffect(() => {
        if(Object.keys(selectedPractice).length > 0 && chatbotLoading === false) {
            if(selectedPractice?.aiUseCaseEndpointUrl.length === 0){
                setShowMicrophoneButton(showMicrophoneButton => true);
            }
            if(isGenericServiceError === false) {
                setShowMicrophoneButton(showMicrophoneButton => true);
            }
        }
    }, [isGenericServiceError, selectedPractice, chatbotLoading]);

    useEffect(() => {
        if(Object.keys(selectedPractice).length > 0) {

            let tmpTitle = selectedPractice.aiUseCaseTranslations.find(language => language.languageName === 'Turkish');

            if(tmpTitle && language === "tr"){
                setTitle(title => tmpTitle.title);
            } else {
                setTitle(title => selectedPractice.title);
            }

        }
    }, [selectedPractice, language]);

    /*
        * Konuşmaya başla logunun gönderilmesi için kontrol yapılıyor.
        * Eğer user rolündeki mesajlar iki olmuş ise log gönderiliyor.
        * Log bir defa atılıyor.
    */
    useEffect(() => {
        let userMessageLength = messages.filter(message => message.role === "user").length;

        if(userMessageLength === 2 && !isStartConversationLogSended) {
            //Seçili pratiğe göre "Konuşmaya Başla" butonu için log atılıyor.
            if(selectedPractice.title === correctAndSpeakAI && !isEmbed)
                analytics.useAnalytics(EventLogs.CORRECT_AND_SPEAK_START_CONVERSATION);

            if(selectedPractice.title === interviewProAI && !isEmbed)
                analytics.useAnalytics(EventLogs.INTERVIEW_PRO_START_CONVERSATION);

            if(selectedPractice.title === levelTestAI && !isEmbed)
                analytics.useAnalytics(EventLogs.PLACEMENT_TEST_STARTED);

            if(selectedPractice.title === grammarExerciseAI && !isEmbed)
                analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_GRAMMAR_TEST_START_SPEAKING_CLICK, logParameters);

            if(selectedPractice.title === videoExerciseAI && !isEmbed)
                analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_AI_PRACTICE_START_SPEAKING_CLICK, logParameters);

            if(selectedPractice.title === examPreparationAI && !isEmbed)
                analytics.useAnalytics(EventLogs.PREPARATION_EXAMS_STARTED);

            setIsStartConversationLogSended(isStartConversationLogSended => true);
        }
    }, [messages, isStartConversationLogSended]);

    /*
        * Yazma moduna geçildiğinde ses kaydı durduruluyor.
        * Mikrofon moduna geçildiğinde yazılan text boşaltılıyor.
    */
    useEffect(() => {
        if(showTextarea) {
            ref?.current.pause();
            setIsRecording(false);
        } else
            setText("");
    }, [showTextarea]);

    /*
        * Chat ile konuşma bittiğinde action gönderiliyor.
    */
    useEffect(() => {
        if(endOfPrompt && bookUnitId !== -1 && bookUnitId !== undefined){
            action.useAction(selectedTermId, bookUnitId, ActionTypes.AWSOME)
                .then(r => {})
                .catch(e => {})
        }

        if(endOfPrompt && selectedPractice.title === levelTestAI && !isEmbed)
            analytics.useAnalytics(EventLogs.PLACEMENT_TEST_COMPLETED);

        if(endOfPrompt && selectedPractice.title === grammarExerciseAI && !isEmbed)
            analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_GRAMMAR_TEST_COMPLETED, logParameters);

        if(endOfPrompt && selectedPractice.title === videoExerciseAI && !isEmbed)
            analytics.useAnalytics(EventLogs.VIDEO_EXERCISE_AI_PRACTICE_COMPLETED, logParameters);
    }, [bookUnitId, endOfPrompt]);

    // Sayfa ilk yüklendiğinde ai sesinin kısık gelmesini kontrol ediyoruz.
    useEffect(() => {
        const audioElement = ref?.current;

        if (audioElement) {
            audioElement.muted = !volumeUp;
        }
    }, [volumeUp]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [])

    return (
        <>
            {/* Ses çaldırmak için kullanılan kullanıcının görmediği alan */}
            <audio ref={ref} id="audioPlayer" controls={false} autoPlay={false}>
                <source src="/sound/empty.mp3" type="audio/mpeg"/>
            </audio>
            <button ref={soundButtonRef} id="soundButton"></button>

            {Object.keys(selectedPractice).length > 0 && (
                <>
                    {loading && <Loading />}
                    {!loading && (
                        <div className="left-page">
                        <div className="w-full flex items-center ml-2 justify-between">
                            <p className="text-secondary font-bold">{title}</p>
                            <button
                                className="ml-auto mr-2 border border-onboarding-border hover:bg-onboarding-bg-select focus:bg-onboarding-bg-select focus:text-base gap-2 h-8 max-w-max px-3 flex flex-row items-center justify-center rounded-[10px]"
                                onClick={handleBackButton}
                            >
                                <span className="material-symbols-outlined">keyboard_return</span>
                                {strings.ai_chatbot.back}
                            </button>
                        </div>
                        <div className="p-5 transparent-section text-start mt-5">
                            {(responseData.length > 0 && isShowUser) && (
                                <>
                                    {(responseData?.find(response => response.title === "Error")) && (
                                        <p className="text-center">{errorLogs.find(err => err.id === +responseData[0].content).name}</p>
                                    )}

                                    {(!responseData?.find(response => response.title === "Error")) && (
                                        <div
                                            className="block relative p-3 rounded-lg mt-2 message-box-endpoint bg-blue-100">
                                            {responseData.map((data, index) => (
                                                <p key={index}>
                                                    <span className="font-bold">{data.title}:</span>
                                                    <span> {data.content}</span>
                                                </p>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}


                            {/* Chat mesaj ekranı */}
                            <div ref={chatScreenRef}></div>

                            {chatbotLoading && (
                                <div className="block max-w-max relative p-3 rounded-lg mt-2 message-box-assistant bg-box before:bg-box ">
                                    <AILoader color="#F16C00"/>
                                </div>
                            )}

                            {transcribeLoading && (
                                <div
                                    className="block max-w-max relative p-3 rounded-lg mt-2 message-box-member bg-primary ml-auto">
                                    <AILoader color="#fff"/>
                                </div>
                            )}

                        </div>

                        {(!endOfPrompt && showMicrophoneButton) && (
                            <>
                                {showMicrophone && (
                                    <>
                                        <div className="flex justify-center items-center gap-2">
                                            <div className="flex justify-center items-center mt-4">
                                                <div className="btn-outer-div">
                                                    <button
                                                        type="button"
                                                        disabled={chatbotLoading}
                                                        className={classNames("button primary-button mt-0 flex gap-2 items-center justify-center", {
                                                            "hidden": isRecording,
                                                            "opacity-70 !cursor-not-allowed": chatbotLoading
                                                        })}
                                                        onClick={startRecording}
                                                    >
                                                        <img src={Recording} className="w-5 h-5" alt="recording"/>
                                                        <p>{strings.ai_chatbot.start}</p>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={classNames("button primary-button mt-0 flex gap-2 items-center justify-center", {
                                                            "hidden": !isRecording
                                                        })}
                                                        onClick={stopRecording}
                                                    >
                                                        <img src={StopRecording} className="w-6 h-6" alt="stop-recording"/>
                                                        <p>{strings.ai_chatbot.stop}</p>
                                                    </button>
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="button relative group primary-button mt-4 flex flex-shrink-0 gap-2 items-center justify-center w-[50px] h-[50px]"
                                                onClick={handleTextareaButton}
                                            >
                                                <img src={Keyboard} className="w-6 h-6" alt="keyboard"/>
                                                <div className="tooltip-top -top-[38px]">{strings.ai_chatbot.switch_write_mode}</div>
                                            </button>
                                            <button
                                                type="button"
                                                className="button relative group bg-primary text-white hover:bg-secondary mt-4 flex flex-shrink-0 gap-2 items-center justify-center w-[50px] h-[50px]"
                                                onClick={handleVolumeControlButton}
                                            >
                                                <span className="material-symbols-outlined">{!volumeUp ? "volume_off" : "volume_up"}</span>
                                                <div className="tooltip-top -top-[38px]">
                                                    {!volumeUp
                                                        ? strings.ai_chatbot.ai_volume_up
                                                        : strings.ai_chatbot.ai_volume_down
                                                    }
                                                </div>
                                            </button>
                                        </div>
                                        {selectedPractice.id === levelTestUseCaseId && (
                                            <p className="text-[14px] text-center mt-5">
                                                <span className="font-bold">{strings.ai_chatbot.note}: </span>
                                                {strings.ai_chatbot.speak_end}
                                            </p>
                                        )}
                                    </>
                                )}

                                {showTextarea && (
                                    <>
                                        <div className="flex justify-center items-end gap-2 mt-4">
                                            <button
                                                type="button"
                                                className="button relative group primary-button flex flex-shrink-0 mb-2 items-center justify-center w-[50px] h-[50px]"
                                                onClick={handleMicrophoneButton}
                                            >
                                                <img src={Recording} className="w-6 h-6" alt="keyboard"/>
                                                <div className="tooltip-top -top-[38px]">{strings.ai_chatbot.switch_speak_mode}</div>
                                            </button>
                                            <div className="relative w-full">
                                                <textarea
                                                    autoFocus={true}
                                                    value={text}
                                                    className={classNames("market-info-input max-w-full placeholder:pt-1 pt-1 -pb-1 resize-none  pr-7 !mb-0 max-h-[100px]", {
                                                        "overflow-y-scroll": textIsScrollable,
                                                        "overflow-y-hidden": !textIsScrollable
                                                    })}
                                                    placeholder={strings.ai_chatbot.write_here}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setText(e.target.value);
                                                        autoResize(e);
                                                    }}
                                                    onKeyDown={e => {
                                                        if (e.keyCode === 13 && !(e.keyCode === 13 && e.shiftKey)) {
                                                            e.preventDefault();

                                                            if(!chatbotLoading && hasNonWhitespaceCharacter(e.target.value)) {
                                                                e.target.style.height = '52px';
                                                                startText(e.target.value);
                                                            }
                                                        }
                                                    }}
                                                ></textarea>
                                                <button
                                                    disabled={chatbotLoading || !hasNonWhitespaceCharacter(text)}
                                                    className={classNames("absolute right-3 bottom-1.5 h-[50px]", {
                                                        "opacity-70 cursor-not-allowed" : chatbotLoading || !hasNonWhitespaceCharacter(text)
                                                    })}
                                                    onClick={() => startText(text)}
                                                >
                                                    <img src={SendIcon} className="w-5 h-5" alt="send-icon"/>
                                                </button>
                                            </div>
                                        </div>
                                        {selectedPractice.id === levelTestUseCaseId && (
                                            <p className="text-[14px] text-center mt-5">
                                                <span className="font-bold">{strings.ai_chatbot.note}: </span>
                                                {strings.ai_chatbot.write_end}
                                            </p>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                        {endOfPrompt && +member?.Status !== MemberStatus.Active && (
                            <div className="mt-5 btn-outer-div">
                                <button 
                                    className="button primary-button" 
                                    onClick={() => {
                                        analytics.useAnalytics(EventLogs.REVIEW_PACKAGES_CLICK);
                                        navigate(url('market.packagecatalog'));
                                    }}
                                >
                                    {strings.teachers.teacher_details.not_student_buy_button}
                                </button>
                            </div>
                        )}
                    </div>
                    )}
                </>
            )}

            <ModalOverlay ref={modalOverlayRef}/>
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.ai_chatbot.give_microphone_permission_text}
                buttonText1={strings.ai_chatbot.switch_write_mode}
                buttonClick1={() => {
                    setShowTextarea(true);
                    setShowMicrophone(false);
                    closeModal(modalOverlayRef, modalRef);
                }}
                buttonText2={strings.auth.form.watch_video}
                buttonClick2={() => window.open(getPermissionTutorialVideoUrl(operatingSystem, browser))}
            />
        </>
    )
})

export default AIChat;
