import React, { useEffect } from 'react';
import { EventLogs } from '../../components/Constants';
import useAnalytics from '../../hooks/useAnalytics';
import { AdvantagesSection, CorporateForm, HeroSection, References } from './components';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const CorporateCustomer = () => {

  const { member } = useAuth();

  const analytics = useAnalytics();
  const navigate = useNavigate();
  
  useEffect(() => {
    analytics.useAnalytics(EventLogs.CORPORATE_CUSTOMER_SCREEN_VIEWED);

    if (+member?.IsCorporate) {
      navigate(-1);
    }
  }, []);

  return (
    <div className="main-content mt-5">
      <HeroSection />
      <AdvantagesSection />
      <CorporateForm />
      <References />
      <div className='left-margin-bottom'></div>
    </div>
  );
};

export default CorporateCustomer;