import {Outlet} from "react-router-dom"
import {Menu, SideBar} from '../../components';
import {useLocalization} from "../../hooks/useLocalization"
import {useSelector} from "react-redux";
import { ReferenceBar } from "./components/DailyLesson/components";
import { FirebaseContext } from "../../store/FirebaseProvider";
import { useContext } from "react";

const SpeakingLessonLayout = () => {
    
    const strings = useLocalization()
    const attendedRenderStatus = useSelector(state => state.componentRenderStore.DailyLesson.Attended);
    const demoOfferBarRenderStatus = useSelector(state => state.componentRenderStore.Bar.DemoOffer.visibility);

    const defaultStickyBar = {
        view: true,
        theme: "dark",
        buttonType: "button",
        buttonPath: "learntogether.reference",
        title: {
            tr: strings.speaking_lesson.components.reference_bar.description,
            en: strings.speaking_lesson.components.reference_bar.description
        },
        buttonText: {
            tr: strings.speaking_lesson.components.reference_bar.button_text,
            en: strings.speaking_lesson.components.reference_bar.button_text
        }
    };

    const attendedLessonStickyBar = useContext(FirebaseContext)?.attendedLessonStickyBar || defaultStickyBar;

    return(
        <>
            <Menu title={strings.speaking_lesson.title} />

            <section className="container">

                <Outlet />

                <SideBar />

            </section>

            {(attendedRenderStatus && attendedLessonStickyBar.view && !demoOfferBarRenderStatus) 
                && <ReferenceBar stickyBar={attendedLessonStickyBar} />
            }
        </>
    )
}

export default SpeakingLessonLayout