import React, { useContext } from 'react';
import { Outlet } from "react-router-dom";
import { Menu, SideBar } from '../../components';
import { MemberStatus } from '../../components/Constants';
import useAuth from '../../hooks/useAuth';
import { useLocalization } from '../../hooks/useLocalization';
import { FirebaseContext } from "../../store/FirebaseProvider";
import CampaignBar from "./components/CampaignBar";
import YearInReviewCard from './components/YearInReviewCard';
import { useSelector } from 'react-redux';

const DashboardLayout = () => {

  const strings = useLocalization();
  const { member } = useAuth();

  const campaignStickyBar = useContext(FirebaseContext)?.campaignStickyBar;
  const demoOfferBarRenderStatus = useSelector(state => state.componentRenderStore.Bar.DemoOffer.visibility);

  return (
    <>
      <Menu title={strings.dashboard.title} />

      <section className="container">

        <Outlet />

        {member?.MemberStatus === MemberStatus.ACTIVE && <YearInReviewCard memberId={member?.MemberId} />}

        <SideBar />

      </section>

      {campaignStickyBar &&
        <CampaignBar campaignStickyBar={campaignStickyBar} demoOfferBarRenderStatus={demoOfferBarRenderStatus} />
      }
    </>
  )
}

export default DashboardLayout