import { fetchApi } from '../index';

export const GetProductCategory = async (token, lang) => {
    const url = '/market/speaking_lesson/product_category/';
 
    const payload = {
        payload: {
            brandId: parseInt(process.env.REACT_APP_BRAND_ID),
        },
        lang: lang,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
    
}

export const GetCategoryProducts = async (productCategoryId, memberStatus, memberId) => {

    const url = '/_services/member/v3/market/get_product_category_details/';

    const payload = {
        brandId: +process.env.REACT_APP_BRAND_ID,
        memberStatus: +memberStatus,
        productCategoryId: productCategoryId,
        memberId: memberId
    };

    return await fetchApi(url, payload);
}


export const CheckDiscountCode = async (discountCode, memberId, memberStatus, productId, languageTitleType) => {
    const url = '/market/speaking_lesson/check_discount_code/';
 
    const payload = {
        payload: {
            campaignCode: discountCode,
            memberId: memberId,
            status: memberStatus,
            productId: productId,
            languageTitleType: languageTitleType
        },
        lang: process.env.REACT_APP_LANG,
        jwtToken: "",
    };

    return await fetchApi(url, payload);
}

export const PaySpeakingLessonPackage = async (payInfo, token) => {
    const url = '/market/speaking_lesson/pay_package/';
 
    const payload = {
        payload: payInfo,
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
    
}

export const AddTerm = async (termInfo, token) => {
    const url = '/market/speaking_lesson/add_term/';
 
    const payload = {
        payload: termInfo,
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
}

export const PayPackageBy3DCreditCard = async (model) => {
    const url = '/_services/member/v1/payment/pay_package_by_3d_credit_card/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const AddStudentInformation = async (studentInfo, token) => {
    const url = '/market/speaking_lesson/add_student_information/';
 
    const payload = {
        payload: studentInfo,
        lang: process.env.REACT_APP_LANG,
        jwtToken: token,
    };

    return await fetchApi(url, payload);
    
}

export const GetBankPosses = async () => {
    const url = '/market/speaking_lesson/get_bank_poses/';

    const payload = {};

    return await fetchApi(url, payload);
};

export const GetInstallmentInformation = async (model) => {

    const url = '/_services/member/v1/campaign/get_installment_information_with_bank_pos/';

    const payload = {
        ...model
    }

    return await fetchApi(url, payload);
}

export const GetMemberCampaigns = async (memberId, languageTitleType) => {

    const url = '/_services/member/v1/campaign/member_campaign_list/';

    const payload = {
        memberId: memberId,
        languageTitleType: languageTitleType
    }

    return await fetchApi(url, payload);
}

export const GetAutoApplyCampaigns = async (languageTitleType, productId, memberId) => {

    const url = '/_services/member/v1/campaign_market_detail/get_auto_apply_campaigns/';

    const payload = {
        languageTitleType: languageTitleType,
        productId: productId,
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetStudentInvoiceInformation = async (memberId) => {

    const url = '/_services/member/v1/accounting_current_card/student_invoice_information/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const GetBrandLocalCompany = async (brandLocalCompanyId) => {

    const url = '/_services/member/v1/brand_local_company/get_brand_local_company_by_id/';

    const payload = {
        brandLocalCompanyId: brandLocalCompanyId
    };

    return await fetchApi(url, payload);
}

export const GetHourlyDiscountRateTranslation = async (languageTitleType, hour) => {

    const url = '/_services/member/v1/hourly_discount_rate/get_hourly_discount_rate_translation/';

    const payload = {
        languageTitleType: languageTitleType,
        hour: hour
    };

    return await fetchApi(url, payload);
}

export const CreateAutoMemberCampaign = async (memberId, sourceType) => {
    const url = '/_services/member/v1/campaign/create_auto_member_campaign/';
 
    const payload = {
        memberId: memberId,
        sourceType: sourceType,
        brandId: +process.env.REACT_APP_BRAND_ID,
    };

    return await fetchApi(url, payload);
}

export const CreateTaskByDailyTaskControl = async (memberId, taskType) => {
    
    const url = '/_services/member/v1/task/create_task_by_daily_task_control/';
 
    const payload = {
        memberId: memberId,
        taskType: taskType
    };

    return await fetchApi(url, payload);
}

export const GetProductDetailsByProductId = async (productId, memberStatus) => {
    const url = '/_services/member/v3/market/get_product_details_by_product_id/';

    const payload = {
        productId: productId,
        memberStatus: memberStatus,
        brandId: +process.env.REACT_APP_BRAND_ID
    };

    return await fetchApi(url, payload);
}