import { fetchApi } from '../index';

export const GetDemoOfferOutcomes = async (memberId, productId, languageTitleType) => {

    const url = '/_services/member/v1/onboarding_question/get_demo_offer_outcomes/';

    const payload = {
        memberId: memberId,
        productId: productId,
        languageTitleType: languageTitleType
    };

    return await fetchApi(url, payload);
}

export const GetMemberDemoOfferEndDate = async (memberId) => {

    const url = '/_services/member/v1/member_demo_offer/get_member_demo_offer_end_date/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}