import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AnnouncementColor, Lightbulb, Timer } from "../assets/svg";
import useAnalytics from "../hooks/useAnalytics";
import useAuth from "../hooks/useAuth";
import { useLocalization } from "../hooks/useLocalization";
import { url } from "../routes/utility";
import { GetDemoOfferOutcomes, GetMemberDemoOfferEndDate } from "../services/DemoOffer/DemoOfferService";
import { updateRenderStatus } from "../store/ComponentRenderSlice";
import { FirebaseContext } from "../store/FirebaseProvider";
import { closeModal, getLanguageIdByLanguageKey, getTimeZone, ISOStringToDate, openModal } from "../utils";
import BottomStickyBar from "./BottomStickyBar";
import { Button } from "./Buttons";
import { ComponentRenderGroups, ComponentRenderKeys, EventLogs, MemberStatus } from "./Constants";
import { Loading } from "./Loading";
import { ModalContent, ModalOverlay } from "./Modals";

const DemoOfferBar = () => {

    const strings = useLocalization();

    const { member } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const analytics = useAnalytics();
    
    const demoOffer = useContext(FirebaseContext)?.demoOffer;
    const language = useSelector(state => state.localizationStore.language);
    const timeZone = getTimeZone();

    const modalOverlayRef = useRef(null);
    const modalContentRef = useRef(null);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activeAccordion, setActiveAccordion] = useState(0);
    const [loading, setLoading] = useState(false);
    const [offer, setOffer] = useState({});

    const [demoOfferEndDate, setDemoOfferEndDate] = useState(null);
    const [demoOfferEndDateLoading, setDemoOfferEndDateLoading] = useState(false);
    const [remainingTime, setRemainingTime] = useState('');
    const [timeLeft, setTimeLeft] = useState('');
    const [isExpired, setIsExpired] = useState(false);
    
    const isOnboarding = location.pathname.includes("/onboarding") || location.pathname === '/success';
    const isAuth = location.pathname.includes("/auth");
    const isMarket = 
        location.pathname.includes("/market/packagecatalog") 
        || location.pathname.includes("/market/checkout") 
        || location.pathname.includes("/market/successful")
        || location.pathname.includes("/market/failed");

    const handleCloseModal = () => {
        setIsModalVisible(false);
        closeModal(modalOverlayRef, modalContentRef);
    }

    const handleAccordionClick = (index) => {
        setActiveAccordion(index);
    };

    const calculateTimeLeft = () => {
        if (!demoOfferEndDate) return '';

        const endTimeStr = ISOStringToDate(demoOfferEndDate, timeZone);
        const endTime = new Date(endTimeStr);
        const now = new Date();
        const difference = endTime.getTime() - now.getTime();

        if (difference <= 0) {
            return '';
        }

        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return {
            displayTime: hours > 0 
                ? strings.demo_offer.last_hour.replace('#hour#', hours).replace('#minute#', minutes).replace('#second#', seconds)
                : strings.demo_offer.last_minute.replace('#minute#', minutes).replace('#second#', seconds),
            modalTime: `${hours > 0 ? `${hours}:` : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        };
    };

    useEffect(() => {
        if (!demoOfferEndDate) return;

        const intervalId = setInterval(() => {
            const times = calculateTimeLeft();
            if (!times) {
                clearInterval(intervalId);
                setIsExpired(true);
                dispatch(updateRenderStatus({
                    group: ComponentRenderGroups.BAR,
                    key: ComponentRenderKeys.DEMO_OFFER,
                    value: { visibility: false }
                }));
                if (isModalVisible) {
                    handleCloseModal();
                }
                return;
            }

            setRemainingTime(times.displayTime);
            setTimeLeft(times.modalTime);
        }, 1000);

        const initialTimes = calculateTimeLeft();
        if (initialTimes) {
            setRemainingTime(initialTimes.displayTime);
            setTimeLeft(initialTimes.modalTime);
            setIsExpired(false);
            dispatch(updateRenderStatus({
                group: ComponentRenderGroups.BAR,
                key: ComponentRenderKeys.DEMO_OFFER,
                value: { visibility: true }
            }));
        } else {
            setIsExpired(true);
            dispatch(updateRenderStatus({
                group: ComponentRenderGroups.BAR,
                key: ComponentRenderKeys.DEMO_OFFER,
                value: { visibility: false }
            }));
        }

        return () => clearInterval(intervalId);
    }, [demoOfferEndDate, timeZone, strings, isModalVisible]);

    useEffect(() => {
        const fetchDemoOfferEndDate = async () => {
            setDemoOfferEndDateLoading(true);
            const result = await GetMemberDemoOfferEndDate(member?.MemberId);

            if(result.status === 200) {
                setDemoOfferEndDate(JSON.parse(result.content));
                analytics.useAnalytics(EventLogs.DEMO_OFFER_BAR_VIEWED);
            } else {
                setDemoOfferEndDate(null);
            }

            setDemoOfferEndDateLoading(false);
        };

        if(+member?.Status === MemberStatus.Candidate) {
            fetchDemoOfferEndDate();
        }
    }, [member?.Status]);

    useEffect(() => {
        const fetchDemoOfferOutcomes = async () => {
            setLoading(true);
            const result = await GetDemoOfferOutcomes(
                member?.MemberId, demoOffer?.productId, getLanguageIdByLanguageKey(language)
            );

            if(result.status === 200) {
                setOffer(JSON.parse(result.content));
            }

            setLoading(false);
        };

        if (isModalVisible) {
            fetchDemoOfferOutcomes();
            analytics.useAnalytics(EventLogs.DEMO_OFFER_MODAL_VIEWED);
        }
    }, [isModalVisible, language]);

    if (isOnboarding || isAuth || isMarket || +member?.Status !== MemberStatus.Candidate) return null;

    if (demoOfferEndDateLoading) return null;
    
    if (!demoOfferEndDate || isExpired) return null;

    return (
        <>
            <BottomStickyBar theme="primary">
                <div className="flex justify-between items-center min-h-[64px]">
                        <div className="flex justify-center items-center gap-3">
                            <div className="w-[50px] h-[50px] bg-white rounded-full flex justify-center items-center">
                            <img src={AnnouncementColor} alt="announcement" height={36} width={36} />
                        </div>
                        <div>
                            <p className="font-bold text-[16px] md:text-[18px]">{strings.demo_offer.title}</p>
                            <p className="md:text-[16px] text-[14px]">{remainingTime}</p>
                        </div>
                    </div>
                    <Button
                        classnames="button primary-button-outline !border-onboarding-border bg-box text-base-text px-2 h-[40px] w-[100px] text-[16px] mt-0 leading-[16px]"
                        text={strings.demo_offer.see_offer}
                        action={() => {
                            analytics.useAnalytics(EventLogs.DEMO_OFFER_BAR_CLICKED);
                            setIsModalVisible(true);
                            openModal(modalOverlayRef, modalContentRef);
                        }}
                    />
                </div>
            </BottomStickyBar>

            <ModalOverlay ref={modalOverlayRef} />
            <ModalContent
                ref={modalContentRef}
                overlayRef={modalOverlayRef}
                show={isModalVisible}
                showCloseButton={false}
                closeAction={handleCloseModal}
                className="fixed w-[92%] sm:w-[460px] min-h-[85vh] sm:min-h-0 sm:h-[760px] bg-white !p-0 !space-y-0 flex flex-col overflow-hidden rounded-[10px]"
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                {/* Sana Özel Teklif Modal Başlığı */}
                <div className="relative bg-gradient-to-r from-primary via-[#1E88E5] to-[#0D47A1] p-3 sm:p-4 rounded-t-[10px]">
                    <h2 className="text-white text-center text-[15px] sm:text-lg font-bold">{strings.demo_offer.modal_title}</h2>
                    <button
                        onClick={handleCloseModal}
                        className="flex justify-center items-center absolute right-2 sm:right-3 top-1.5 w-6 h-6 opacity-40 hover:opacity-100 before:absolute before:bg-[#fff] before:content-[''] before:h-6 before:w-[2px] before:rounded-full before:rotate-45 after:absolute after:content-[''] after:bg-[#fff] after:rounded-full after:h-6 after:w-[2px] after:-rotate-45"
                    ></button>
                </div>

                {loading && <div className="mt-5"><Loading /></div>}

                {!loading && (
                    <div className="flex-1 px-3 sm:px-5 pt-4 sm:pt-5 pb-4 sm:pb-6 flex flex-col relative">
                        <div className="flex items-start">
                            <div className="w-7 h-7 sm:w-9 sm:h-9 rounded-full bg-[#EBF6FF] flex items-center justify-center mr-2.5 sm:mr-3 flex-shrink-0">
                                <img 
                                    src={Lightbulb} 
                                    className="w-4 h-4 sm:w-6 sm:h-6" 
                                    style={{
                                        filter: 'invert(40%) sepia(95%) saturate(1752%) hue-rotate(199deg) brightness(100%) contrast(101%)'
                                    }}
                                />
                            </div>
                            <p className="text-[13px] sm:text-[15px] flex-1 font-bold leading-snug">{offer.reasonToLearnEnglishOutcome}</p>
                        </div>
                        
                        <hr />

                        {/* Accordions */}
                        <div className="space-y-2 w-full max-w-full sm:max-w-none mx-auto mb-4 sm:mb-5">
                            <div 
                                className="group outline-none bg-[#F8FBFF] rounded-lg hover:bg-[#EBF6FF] transition-colors duration-200" 
                                onClick={() => handleAccordionClick(0)}
                            >
                                <div className="flex items-center cursor-pointer p-2.5 sm:p-3">
                                    <div className="w-1 h-1 sm:w-1.5 sm:h-1.5 rounded-full bg-primary mr-2"></div>
                                    <p className="text-[13px] sm:text-[15px] flex-1 font-bold capitalize">{strings.demo_offer.stand_teacher}</p>
                                    <span className={`material-symbols-outlined text-primary text-xs sm:text-sm transition-transform duration-300 ${activeAccordion === 0 ? 'rotate-90' : ''}`}>
                                        arrow_forward_ios
                                    </span>
                                </div>
                                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${activeAccordion === 0 ? 'max-h-[200px] pb-3 opacity-100' : 'max-h-0 opacity-0'}`}>
                                    <div className="px-4 sm:px-6 border-l-2 ml-2 sm:ml-3 border-primary/20">
                                        <p className="text-[12px] sm:text-sm text-base-text leading-relaxed">
                                            {strings.demo_offer.stand_teacher_description}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div 
                                className="group outline-none bg-[#F8FBFF] rounded-lg hover:bg-[#EBF6FF] transition-colors duration-200" 
                                onClick={() => handleAccordionClick(1)}
                            >
                                <div className="flex items-center cursor-pointer p-2.5 sm:p-3">
                                    <div className="w-1 h-1 sm:w-1.5 sm:h-1.5 rounded-full bg-primary mr-2"></div>
                                    <p className="text-[13px] sm:text-[15px] flex-1 font-bold capitalize">{strings.demo_offer.mentor_support}</p>
                                    <span className={`material-symbols-outlined text-primary text-xs sm:text-sm transition-transform duration-300 ${activeAccordion === 1 ? 'rotate-90' : ''}`}>
                                        arrow_forward_ios
                                    </span>
                                </div>
                                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${activeAccordion === 1 ? 'max-h-[200px] pb-3 opacity-100' : 'max-h-0 opacity-0'}`}>
                                    <div className="px-4 sm:px-6 border-l-2 ml-2 sm:ml-3 border-primary/20">
                                        <p className="text-[12px] sm:text-sm text-base-text leading-relaxed">
                                            {strings.demo_offer.mentor_support_description}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div 
                                className="group outline-none bg-[#F8FBFF] rounded-lg hover:bg-[#EBF6FF] transition-colors duration-200" 
                                onClick={() => handleAccordionClick(2)}
                            >
                                <div className="flex items-center cursor-pointer p-2.5 sm:p-3">
                                    <div className="w-1 h-1 sm:w-1.5 sm:h-1.5 rounded-full bg-primary mr-2"></div>
                                    <p className="text-[13px] sm:text-[15px] flex-1 font-bold capitalize">{offer.expectation}</p>
                                    <span className={`material-symbols-outlined text-primary text-xs sm:text-sm transition-transform duration-300 ${activeAccordion === 2 ? 'rotate-90' : ''}`}>
                                        arrow_forward_ios
                                    </span>
                                </div>
                                <div className={`overflow-hidden transition-all duration-300 ease-in-out ${activeAccordion === 2 ? 'max-h-[200px] pb-3 opacity-100' : 'max-h-0 opacity-0'}`}>
                                    <div className="px-4 sm:px-6 border-l-2 ml-2 sm:ml-3 border-primary/20">
                                        <p className="text-[12px] sm:text-sm text-base-text leading-relaxed">
                                            {offer.expectationOutcome}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-auto">
                            {/* Teklif Detayları */}
                            <div className="border-t border-b border-primary/15 py-2.5 sm:py-3 mb-4 sm:mb-5">
                                <p className="text-[14px] sm:text-[15px] font-bold text-center">
                                    {strings.demo_offer.education_package_title
                                        .replace("#month#", offer.productDetails?.month)
                                    }
                                </p>
                                <p className="text-[13px] sm:text-[15px] font-medium text-center text-base-text">
                                    {strings.demo_offer.education_package_description
                                        .replace("#day#", offer.productDetails?.day)
                                        .replace("#minute#", offer.productDetails?.minute)
                                    }
                                </p>
                            </div>

                            {/* Fiyat ve Zamanlayıcı */}
                            <div className="bg-[#EBF6FF] p-3 sm:p-4 rounded-lg mb-4 sm:mb-5">
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center">
                                        <span className="text-xs sm:text-sm font-medium mr-1.5 sm:mr-2">{strings.demo_offer.special_price}:</span>
                                        <span className="text-lg sm:text-xl font-bold text-secondary">
                                            {offer.productDetails?.totalPrice}{offer.productDetails?.currencySymbol}
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-1.5 sm:gap-2">
                                        <div className="bg-white/80 rounded-full p-1.5 sm:p-2">
                                            <img src={Timer} className="w-[18px] h-[18px] sm:w-[18px] sm:h-[18px]" alt="timer" />
                                        </div>
                                        <div className="flex items-center">
                                            <span className="text-sm sm:text-base font-bold text-primary">{timeLeft}</span>
                                            <span className="text-[11px] sm:text-sm ml-1">{strings.demo_offer.time_left}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* CTA Butonu */}
                            <div className="flex items-center justify-center">
                                <Button
                                    classnames="button primary-button w-full max-w-full sm:max-w-[330px]"
                                    text={strings.demo_offer.start_now}
                                    action={() => {
                                        handleCloseModal();
                                        navigate(url("market.checkout"), { state: { externalProductId: demoOffer?.productId } });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </ModalContent>
        </>
    )
}

export default DemoOfferBar;