import '../../assets/css/market.css'
import {useLocalization} from "../../hooks/useLocalization";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {changeLanguage} from "../../store/LocalizationSlice";
import classNames from "classnames";
import {closeModal, decodeToken, isJWT, openModal} from "../../utils";
import useAuth from "../../hooks/useAuth";
import {parseMobileKey} from "../../utils/Token";
import {AdditionalRight, EducationalPackages, EducationPackages, Gifts} from "../../assets/svg";
import {MemberStatus} from "../../components/Constants";
import {url} from "../../routes/utility";
import {ModalOverlay, ModalTwoButtons} from "../../components";
import {GetMarketTermList} from '../../services/Market/MarketPostponeService';

const Home = () => {

    const strings = useLocalization();

    // Mobil için dil ve key parametreden alınıyor.
    const { lang, key } = useParams();
    const { member } = useAuth();

    // Öğrencinin term'lerinin tutulduğu state
    const [terms, setTerms] = useState([]);

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isMobile = location.pathname.includes("/mobile_market/");
    const marketMenu = [
        {
            title: strings.market.speakingLesson.title,
            icon: EducationalPackages,
            path: { mobilePath: "mobile_market_package_catalog", webPath: "market.packagecatalog", isMemberStatusControl: false },
            isButton: true,
            isActiveTermControl: false
        },
        {
            title: strings.market.additional_rights.title,
            icon: AdditionalRight,
            path: { mobilePath: "mobile_market_right_catalog", webPath: "market.rightcatalog", isMemberStatusControl: true },
            isButton: true,
            isActiveTermControl: false
        },
        {
            title: strings.market.gifts.title,
            icon: Gifts,
            path: "https://www.konusarakogren.com/gift/?q=",
            isButton: false,
            isActiveTermControl: false
        },
        {
            title: strings.speaking_lesson.speaking_lesson_user.education_packages,
            icon: EducationPackages,
            path: { mobilePath: "mobile_market_education_packages", webPath: "market.educationpackages", isMemberStatusControl: false },
            isButton: true,
            isActiveTermControl: false
        }
    ];

    const isJWTToken = isJWT(key);
    const memberInfo = isMobile
        ? isJWTToken ? decodeToken(key) : parseMobileKey(key)
        : member;

    // Dışarıya gönderilecek URI bilgisi oluşturuluyor.
    const outsourceInformation = btoa(`m=${memberInfo?.MemberId}`);

    const modalOverlayRef = useRef();
    const modalRef = useRef();

    // Yönlendirmeli kontrol eden fonksiyon
    const handleRedirect = (webPath = "", mobilePath = "", isMemberStatusControl = false, isActiveTermControl = false) => {
        if((isMemberStatusControl && (+memberInfo.Status === MemberStatus.Candidate || +memberInfo.Status === MemberStatus.Passive)) || (isActiveTermControl && terms?.length == 0)){
            openModal(modalOverlayRef, modalRef);
        } else {
            let redirectUrl = webPath;

            if (isMobile) {
                redirectUrl = mobilePath;
            }

            navigate(url(redirectUrl));
        }
    }

    // Dış yönlendirmeleri kontrol eden fonksiyon (Örnek: Gift)
    const handleRedirectOutsource = (e) => {
        if(+memberInfo.Status === MemberStatus.Passive || +memberInfo.Status === MemberStatus.Candidate) {
            e.preventDefault();
            openModal(modalOverlayRef, modalRef);
        }
    }

    // Modal içerisinde olan yönlendirmeleri kontrol eden fonksiyon
    const handleRedirectModalButton = () => {

        let redirectUrl = "market.packagecatalog";

        if(isMobile) {
            redirectUrl = "mobile_market_package_catalog"
        }

        navigate(url(redirectUrl));
    }

    // Path'ten gelen yönlendirme isteklerine cevap veren fonksiyon
    const navigateToPath = (path) => {
        if(path === "package_catalog")
            navigate(url("mobile_market_package_catalog"));
        else if (path === "right_catalog")
            navigate(url("mobile_market_right_catalog"));
        else if (path === "gift")
            window.open(`https://www.konusarakogren.com/gift/?q=${outsourceInformation}`, isMobile ? "" : "_blank");
        else if (path === "education_program")
            navigate(url("mobile_market_education_packages"));
        else if (path?.includes("education_program/sub/id=")) {
            const idValue = path.split("id=")[1];
            navigate(url("mobile_market_sub_category", { programId: idValue }));
        } else if (path?.includes("education_program/detail/id=")) {
            const idValue = path.split("id=")[1];
            navigate(url("mobile_market_education_packages_detail", { programId: idValue }));
        } else if (path === "checkout") {
            navigate(url("mobile_market_checkout"));
        }
    }

    // Mobilden gelen path isteklerine göre sayfalara yönlendirme yapıyoruz.
    useEffect(() => {

        // Öğrencinin aktif term'leri servisten getiriliyor
        GetMarketTermList(memberInfo?.MemberId)
            .then(result => {
                if(result.status === 200) {
                    setTerms(terms => JSON.parse(result.content));
                }
            })
            .catch()
        if(!isJWTToken && isMobile){
            navigateToPath(memberInfo?.Path);
        }
    }, []);

    // Token'a göre ayrıştırılan memberInfo değeri sessionStorage'a set ediliyor.
    useEffect(() => {
        if(Object.keys(memberInfo).length > 0 && isMobile){
             sessionStorage.setItem("m", btoa(encodeURIComponent(JSON.stringify(memberInfo))));
        }
    }, [])

    // Mobil için path'ten gelen dile göre dil değiştirir.
    useEffect(() => {
        lang && dispatch(changeLanguage(lang));
    }, [lang])

    // Kurumsal kullanıcıların market sayfasına girişini engeller.
    useEffect(() => {
        if(Object.keys(memberInfo).length > 0 && +memberInfo?.IsCorporate)
            navigate(url("dashboard"));
    }, [])

    // useEffect(() => {
    //     if (isMobile) {
    //         const page = {
    //             "second_page": false
    //         }
    //         window.ReactNativeWebView.postMessage(JSON.stringify(page));
    //     }
    // }, [])

    return (
        <>
            <div className={classNames("", {
                "main-content-mobile": isMobile,
                "main-content": !isMobile
            })}>
                <div className="select-section">

                    {marketMenu.map((item, index) => (
                        item.isButton ? (
                            <div key={index} className="mb-[10px]">
                                <button
                                    className="select-button"
                                    onClick={() => handleRedirect(item.path.webPath, item.path.mobilePath, item.path.isMemberStatusControl, item.isActiveTermControl)}
                                >
                                    <img className="w-[120px] h-[120px] lg:w-[140px] lg:h-[140px]" src={item.icon} alt="button-icon" />
                                </button>
                                <p className="mt-[10px] max-w-[120px] lg:max-w-[140px] mx-auto">{item.title}</p>
                            </div>
                        ) : (
                            <div key={index} className="mb-[10px]">
                                <a
                                    className="select-button"
                                    href={`${item.path}${outsourceInformation}`}
                                    target={isMobile ? "" : "_blank"}
                                    onClick={(e) => handleRedirectOutsource(e)} rel="noreferrer"
                                >
                                    <img className="w-[120px] h-[120px] lg:w-[140px] lg:h-[140px]" src={item.icon} alt="button-icon"/>
                                </a>
                                <p className="mt-[10px] max-w-[120px] lg:max-w-[140px] mx-auto">{item.title}</p>
                            </div>
                        )
                    ))}

                </div>
            </div>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.support.main_page.not_student_content}
                buttonText1={strings.support.main_page.not_student_buy_button}
                buttonClick1={handleRedirectModalButton}
                buttonText2={strings.support.main_page.not_student_cancel_button}
                buttonClick2={() => {closeModal(modalOverlayRef, modalRef);}}
            />
        </>
    )
}

export default Home;