import React, {lazy} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
import './index.css';
import App from './App';
import store from './store';
import {disableReactDevTools} from '@fvilers/disable-react-devtools';
import {Provider} from "react-redux"
import FirebaseProvider from "./store/FirebaseProvider"
import {getOS} from "./utils";
import {OperatingSystems} from "./components/Constants";
import {AssignTeacherModal, AssignTeacherSandyModal, CallBarNotAvaliable, DemoOfferBar} from "./components";
import {Nps} from "./popups";

if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
}

let CallBar = null;
if (getOS() !== OperatingSystems.IOS) {
    CallBar = lazy(() => import('./components/CallBar'));
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <FirebaseProvider>
            <Router>
                {(  !window.location.pathname.includes('/mobile_market') &&
                    !window.location.pathname.includes('/market_pending') &&
                    !window.location.pathname.includes('/embed') &&
                    !window.location.pathname.includes('/mobile/joinfamilyinvite') &&
                    getOS() !== OperatingSystems.IOS
                ) && <CallBar />}
                {(  !window.location.pathname.includes('/mobile_market') &&
                    !window.location.pathname.includes('/market_pending') &&
                    !window.location.pathname.includes('/embed') &&
                    !window.location.pathname.includes('/mobile/joinfamilyinvite') &&
                    getOS() === OperatingSystems.IOS
                ) && <CallBarNotAvaliable />}
                {(  !window.location.pathname.includes('/mobile_market') &&
                    !window.location.pathname.includes('/market_pending') &&
                    !window.location.pathname.includes('/embed') &&
                    !window.location.pathname.includes('/mobile/joinfamilyinvite')
                ) && <DemoOfferBar />}
                <Nps />
                <AssignTeacherSandyModal />
                <AssignTeacherModal />
                <App />
            </Router>
        </FirebaseProvider>
    </Provider>
);